import Sticker from "../../components/admin/sticker";
import {useLocation} from "react-router-dom";
import {StickerForPrint} from "../../api/responses";
import NoDataFound from "../../components/NoDataFound";
import './DemoStickerPrint.css'

const DemoStickerPrintedPage = () => {
  const location = useLocation();
  const stickers = location.state as StickerForPrint[]

  if (!stickers) {
    return <NoDataFound />
  }

  return (
    <div className="passcodeGrid">
        {
          stickers.map(sticker => {
            return (
              <div className="sticker">
                <Sticker key={sticker.visible_id} visibleBarcodeValue={sticker.visible_id} hiddenQrCodeValue={sticker.hidden_id} />
              </div>
            )
          })
        }
    </div>
  )
}

export default DemoStickerPrintedPage;