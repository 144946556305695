import BrandList from "../../components/admin/BrandList";
import React, {useEffect, useState} from "react";
import {getAllBrand} from "../../api/admin";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {Brand} from "../../model";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {Backdrop, Box, CircularProgress, Container, FormLabel, Grid, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const BrandManagement = () => {
  const axios = useAxiosPrivate()
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [brands, setBrands] = useState<Brand[]>([])
  const [totalBrands, setTotalBrands] = useState<number>(0)

  const [id, setId] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(10)

  const sendNotification = useNotificationStore(state => state.sendNotification)

  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController)

    return () => {
      abortController.abort();
    }
    // eslint-disable-next-line
  }, [page, perPage])

  const fetchData = (abortController: AbortController) => {
    setLoading(true)
    getAllBrand(axios, abortController, {
      id: id,
      name: name,
      page: page,
      per_page: perPage
    }).then(response => {
      if (response.result) {
        setBrands(response.data.data)
        setTotalBrands(response.data.total)
        setLoading(false)
        setSuccess(true)
      } else {
        setLoading(false)
        setSuccess(false)
        sendNotification('error', response.message)
      }
    }).finally(() => {
      abortController.abort();
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const abortController = new AbortController();
    fetchData(abortController)
  }

  return (
    <Container maxWidth="xl" sx={{
      marginTop: 8
    }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Typography >
          Loading
        </Typography>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box component="form"
           mt={3}
           noValidate
           onSubmit={handleSubmit}
      >
        <Grid container spacing={2} sx={{
          marginBottom: 3,
          display: 'fex',
          alignItems: 'center'
        }}>
          <Grid item xs={12} >
            <FormLabel>Filter</FormLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={id}
              id="id"
              name="id"
              label="Brand Id"
              autoComplete="on"
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              value={name}
              id="name"
              name="name"
              label="Brand Name"
              autoComplete="on"
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              type="submit"
              variant="contained"
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </Box>

      {
        success && <BrandList
              brands={brands}
              totalBrands={totalBrands}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}/>
      }
    </Container>
  )
}

export default BrandManagement;