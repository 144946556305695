import {Box, Container, Paper, Typography} from "@mui/material";

interface MessageContainerProps {
  title?: string
  content?: string
}

const MessageContainer = ({title, content}: MessageContainerProps) => {
  return (
    <Container component="main" sx={{marginTop: 8}}>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          width: '100%',
          height: '100%',
          padding: 10
        }
      }}>
        <Paper elevation={8}>
          <Typography variant="h6">
            {title} <br/>
            {content}
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}

export default MessageContainer;