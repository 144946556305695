import {useAuthenticationStore} from "../stores/useAuthenticationStore";
import {useNotificationStore} from "../stores/useNotificationStore";
import useAxiosPrivate from "./useAxiosPrivate";
import {logout} from "../api/authentication";

const useSignOut = () => {
  const axios = useAxiosPrivate()
  const removeAuthentication = useAuthenticationStore((state) => state.removeAuthentication)
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  return () => {
    logout(axios).then(response => {
      if (response.result) {
        removeAuthentication()
        sendNotification("success", response.message);
      } else {
        sendNotification("error", response.message);
      }
    });
  }
}

export default useSignOut