import UsersList from "../../../components/admin/superadmin/UsersList";
import {Backdrop, Box, CircularProgress, Container, FormLabel, Grid, TextField} from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import React, {useEffect, useState} from "react";
import {getAllUser} from "../../../api/superadmin";
import {User} from "../../../model";
import {useNotificationStore} from "../../../stores/useNotificationStore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const UserManagement = () => {
  const axios = useAxiosPrivate()
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [totalUsers, setTotalUsers] = useState<number>(0)

  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [brandId, setBrandId] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(10)

  const sendNotification = useNotificationStore(state => state.sendNotification)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [page, perPage])

  const fetchData = () => {
    setLoading(true)
    getAllUser(axios, {
      email: email,
      phone: phone,
      brand_id: brandId,
      page: page,
      per_page: perPage
    }).then(response => {
      if (response.result) {
        setUsers(response.data.data)
        setTotalUsers(response.data.total)
        setLoading(false)
        setSuccess(true)
      } else {
        setLoading(false)
        setSuccess(false)
        sendNotification('error', response.message)
      }
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData()
  }

  return (
    <Container maxWidth="xl" sx={{
      marginTop: 8
    }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Typography >
          Loading
        </Typography>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box component="form"
           mt={3}
           noValidate
           onSubmit={handleSubmit}
      >
        <Grid container spacing={2} sx={{
          marginBottom: 3,
          display: 'fex',
          alignItems: 'center'
        }}>
          <Grid item xs={12} >
            <FormLabel>Filter</FormLabel>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              value={email}
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              value={phone}
              id="phone"
              name="phone"
              label="Phone"
              autoComplete="phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              value={brandId}
              id="brand-id"
              name="brand_id"
              label="BrandId"
              onChange={(e) => setBrandId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type="submit"
              variant="contained"
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </Box>

      {
        success && <UsersList users={users}
                              totalUsers={totalUsers}
                              page={page}
                              perPage={perPage}
                              setPage={setPage}
                              setPerPage={setPerPage}/>
      }
    </Container>
  )
}

export default UserManagement;