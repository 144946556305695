import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthenticationStore } from "../stores/useAuthenticationStore";
import {RoleConstants} from "../constants/constants";

interface RequireSignInUserProps {
  allowedRoles: string[]
}

function RequireSignInUser({ allowedRoles } : RequireSignInUserProps) {
  const location = useLocation();
  const authState = useAuthenticationStore((state) => state.authState);

  if (authState?.roles?.find(role => allowedRoles?.includes(role))) {

    if (location.pathname === '/' && authState?.roles?.find(role => RoleConstants.ROLE_ADMIN === role)) {
      return <Navigate to="/admin" replace />
    }

    if (location.pathname === '/' && authState?.roles?.find(role => RoleConstants.ROLE_BRAND === role)) {
      return <Navigate to="/brand" replace />
    }

    return <Outlet />
  }

  if (authState?.userId) {
    return <Navigate to='/unauthorized' state={location?.pathname} replace />
  } else {
    return <Navigate to='/signin' state={location?.pathname} replace />
  }
}

export default RequireSignInUser;
