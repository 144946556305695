import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../Copyright";
import { validateBdPhone, validateEmail } from "../../utils/validators";
import { useNotificationStore } from "../../stores/useNotificationStore";
import { isEmpty, isNotEmpty } from "../../utils/stringUtils";
import { getRequiredMessage } from "../../constants/errormessages";
import axios from "../../api/axios";
import { useNavigate, Link } from "react-router-dom";
import {register} from "../../api/authentication";

const theme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [nameErr, setNameErr] = useState<boolean>(false);
  const [nameErrText, setNameErrText] = useState<string>("");

  const [phone, setPhone] = useState<string>("");
  const [phoneErr, setPhoneErr] = useState<boolean>(false);
  const [phoneErrText, setPhoneErrText] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [emailErrText, setEmailErrText] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<boolean>(false);
  const [passwordErrText, setPasswordErrText] = useState<string>("");

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState<boolean>(false);
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState<string>("");

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const sendNotification = useNotificationStore((state) => state.sendNotification);

  const handleSetName = (val: string) => {
    setName(val);
    if (val === "") {
      setNameErr(true);
      setNameErrText("Name can not be empty!");
      return;
    }

    if (val.length < 4) {
      setNameErr(true);
      setNameErrText("Name must be greater than 3 character!");
      return;
    }

    if (val.length > 30) {
      setNameErr(true);
      setNameErrText("Name must not have more than 30 characters!");
      return;
    }

    setNameErr(false);
    setNameErrText("");
  };

  const handleSetPhone = (val: string) => {
    setPhone(val);
    if (val === "") {
      setPhoneErr(true);
      setPhoneErrText("Phone can not be empty!");
      return;
    }

    if (!validateBdPhone(val)) {
      setPhoneErr(true);
      setPhoneErrText("Invalid BD Phone!");
      return;
    }

    setPhoneErr(false);
    setPhoneErrText("");
  };

  const handleSetEmail = (val: string) => {
    setEmail(val);
    if (!validateEmail(val)) {
      setEmailErr(true);
      setEmailErrText("Invalid email format!");
      return;
    } else {
      setEmailErr(false);
      setEmailErrText("");
      return;
    }
  };

  const handleSetPassword = (val: string) => {
    setPassword(val);

    if (confirmPassword !== "" && val !== confirmPassword) {
      setPasswordErr(true);
      setPasswordErrText("Password must match with Confirm Password!");
      return;
    }

    if (val === "") {
      setPasswordErr(true);
      setPasswordErrText("Password can not be empty!");
      return;
    }

    if (val.length < 8) {
      setPasswordErr(true);
      setPasswordErrText("Password must be greater than 8 character!");
      return;
    }

    if (val.length > 80) {
      setPasswordErr(true);
      setPasswordErrText("Password must be less than 80 character!");
      return;
    }

    setPasswordErr(false);
    setPasswordErrText("");
  };

  const handleSetConfirmPassword = (val: string) => {
    setConfirmPassword(val);

    if (val === "") {
      setConfirmPasswordErr(true);
      setConfirmPasswordErrText("Confirm Password can not be empty!");
      return;
    }

    if (val !== password) {
      setConfirmPasswordErr(true);
      setConfirmPasswordErrText("Confirm Password must match with Password!");
      return;
    }

    setConfirmPasswordErr(false);
    setConfirmPasswordErrText("");
  };

  const handleExistingPhoneCheck = async () => {
    if (!phoneErr && phone !== "") {
      try {
        const response = await axios.get(`/check-existing-user?phone=${phone}`);
        if (!response.data?.result) {
          setPhoneErr(true);
          setPhoneErrText(response.data?.message);
        }
      } catch (err) {
        console.log("Error occurred during existing phone check!", err);
      }
    }
  };

  const handleExistingEmailCheck = async () => {
    if (!emailErr && email !== "") {
      try {
        const response = await axios.get(`/check-existing-user?email=${email}`);
        if (!response.data?.result) {
          setEmailErr(true);
          setEmailErrText(response.data?.message);
        }
      } catch (err) {
        console.log("Error occurred during existing email check!", err);
      }
    }
  };

  useEffect(() => {
    if (nameErr || phoneErr || emailErr || passwordErr || confirmPasswordErr) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [nameErr, phoneErr, emailErr, passwordErr, confirmPasswordErr]);

  const resetAllStates = () => {
    setName("");
    setPhone("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setDisableSubmit(true)
    e.preventDefault();
    if (isEmpty(name)) {
      setNameErr(true);
      setNameErrText(getRequiredMessage("Name"));
    }
    if (isEmpty(phone)) {
      setPhoneErr(true);
      setPhoneErrText(getRequiredMessage("Phone"));
    }
    if (isEmpty(email)) {
      setEmailErr(true);
      setEmailErrText(getRequiredMessage("Email"));
    }
    if (isEmpty(password)) {
      setPasswordErr(true);
      setPasswordErrText(getRequiredMessage("Password"));
    }
    if (isEmpty(confirmPassword)) {
      setConfirmPasswordErr(true);
      setConfirmPasswordErrText(getRequiredMessage("Confirm Password"));
    }

    if (
      isNotEmpty(password) &&
      isNotEmpty(confirmPassword) &&
      password !== confirmPassword
    ) {
      sendNotification("error", "Password and confirm password doesn't match");
    }

    if (nameErr || phoneErr || emailErr || passwordErr || confirmPasswordErr) {
      return;
    }

    if (
      isEmpty(name) ||
      isEmpty(phone) ||
      isEmpty(email) ||
      isEmpty(password) ||
      isEmpty(confirmPassword)
    ) {
      return;
    }

    const response = await register(axios, {name: name, phone: phone, email: email, password: password});
    if (response.result) {
      sendNotification("success", "Registration Successful! Please Sign In!");
      resetAllStates();
      navigate("/signin", {replace: true});
    } else {
      sendNotification("error", response.message);
    }

    setDisableSubmit(false)
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                  onChange={(e) => handleSetName(e.target.value)}
                  error={nameErr}
                  helperText={nameErrText}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  autoComplete="phone"
                  onBlur={handleExistingPhoneCheck}
                  onChange={(e) => handleSetPhone(e.target.value)}
                  error={phoneErr}
                  helperText={phoneErrText}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onBlur={handleExistingEmailCheck}
                  onChange={(e) => handleSetEmail(e.target.value)}
                  error={emailErr}
                  helperText={emailErrText}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => handleSetPassword(e.target.value)}
                  error={passwordErr}
                  helperText={passwordErrText}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  id="confirm-password"
                  autoComplete="new-password"
                  onChange={(e) => handleSetConfirmPassword(e.target.value)}
                  error={confirmPasswordErr}
                  helperText={confirmPasswordErrText}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disableSubmit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin" style={{
                  fontSize: 14,
                  textDecoration: "none"
                }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
