import {Autocomplete, Box, TextField} from "@mui/material";
import {AutocompleteResponse} from "../../api/responses";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {brandAutoComplete} from "../../api/admin";
import debounce from "lodash.debounce";
import {isEmpty} from "../../utils/stringUtils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

interface BrandAutoCompleteProps {
  setBrandIdHandler: Dispatch<SetStateAction<string>>
  setBrandIdErrHandler: Dispatch<SetStateAction<boolean>>
}

const BrandAutoComplete = ({setBrandIdHandler, setBrandIdErrHandler}: BrandAutoCompleteProps) => {
  const axios = useAxiosPrivate();

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const [brandSearchValue, setBrandSearchValue] = useState<string>('');
  const [brandOptions, setBrandOptions] = useState<readonly AutocompleteResponse[]>([])

  const handleSetBrandSearchValue = debounce((val: string) => {
    setBrandSearchValue(val)
  }, 300)

  const handleSetBrandId = (res: AutocompleteResponse | null) => {
    if (!res || isEmpty(res.id)) {
      setBrandIdErrHandler(true)
      return
    }
    setBrandIdHandler(res.id);
    setBrandIdErrHandler(false)
  }

  useEffect(() => {
    if (isEmpty(brandSearchValue)) {
      return
    }
    setLoading(true)
    const abortController = new AbortController();
    brandAutoComplete(axios, abortController, {search_term: brandSearchValue})
      .then(response => {
        setBrandOptions(response.data ?? [])
        setLoading(false)
      })

    return () => {
      abortController.abort();
    }
    // eslint-disable-next-line
  }, [brandSearchValue])

  return (
    <Autocomplete
      disablePortal
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      id="brand-auto-complete"
      getOptionLabel={(option: AutocompleteResponse) => option.name}
      options={brandOptions}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={"No brand found with provided name"}
      sx={{ width: 300 }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Search a Brand"/>}
      onInputChange={(event, value) => handleSetBrandSearchValue(value)}
      onChange={(event, value) => handleSetBrandId(value)}
    />

  )
}

export default BrandAutoComplete;