import { useQRCode } from "next-qrcode";

interface HiddenQrCodeProps {
  value: string;
}

const HiddenQrCode = ({ value }: HiddenQrCodeProps) => {
  const { Canvas } = useQRCode();

  return (
    <Canvas
      text={value}
      options={{
        type: "image/jpeg",
        quality: 1,
        //level: 'H',
        margin: 3,
        width: 72,
      }}
    />
  );
};

export default HiddenQrCode;
