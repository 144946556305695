import {BarChart, Bar, XAxis, YAxis, Cell, Tooltip} from 'recharts';
import {Statistics} from "../../api/responses";
import {colors} from "@mui/material"
import NoDataFound from "../NoDataFound";
import React from "react";

interface CustomBarChartProps {
  data: Statistics[]
}

const CustomBarChart = ({data}: CustomBarChartProps) => {

    const COLORS = [
        "#A2AB20",
        "#FEAE65",
        "#2D87BB",
        "#FF0000",
        "#AADEA7",
        "#F66D44",
        "#64C2A6",
    ];

    const getBarChart = () => {
        return (
            <BarChart width={900} height={500} data={data}>
                <XAxis dataKey="status"/>
                <YAxis/>
                <Bar
                    legendType="line"
                    dataKey="total"
                    fill={colors['amber'].A700}
                    label
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Bar>
                <Tooltip/>
            </BarChart>
        );
    }

  if (!data) {
    return (
      <NoDataFound />
    )
  }
    return (
        getBarChart()
    );
}

export default CustomBarChart;