import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Copyright from "../Copyright";
import React, {useEffect, useState} from "react";
import {getRequiredMessage} from "../../constants/errormessages";
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { green } from '@mui/material/colors'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {generateSticker} from "../../api/admin";

const MAX_ALLOWED_QUANTITY_PER_BATCH = 25000

const GenerateSticker = () => {
  const [quantity, setQuantity] = useState<number>(0)
  const [quantityErr, setQuantityErr] = useState<boolean>(false)
  const [quantityErrText, setQuantityErrText] = useState<string>("")

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const axios = useAxiosPrivate()
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  const handleSetQuantity = (val : string) => {
    const qty = Number(val)
    if (isNaN(qty)) {
      setQuantityErr(true)
      setQuantityErrText("Quantity must be a number!")
      return
    }

    setQuantity(qty)
    if (qty <= 0) {
      setQuantityErr(true)
      setQuantityErrText("Quantity must be greater than zero!")
      return
    }
    if (qty > MAX_ALLOWED_QUANTITY_PER_BATCH) {
      setQuantityErr(true)
      setQuantityErrText("Max allowed quantity per batch is 25000")
      return
    }

    setQuantityErr(false)
    setQuantityErrText("")
  }

  useEffect(() => {
    if (quantityErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [quantityErr])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableSubmit(true)

    if (quantity === 0) {
        setQuantityErr(true);
        setQuantityErrText(getRequiredMessage("Quantity"));
    }

    if (quantityErr || quantity === 0) {
        return;
    }

    setLoading(true)
    generateSticker(axios, quantity).then(response => {
      if (response.result) {
        setSuccess(true)
      } else {
        setSuccess(false)
        sendNotification('error', response.message);
      }
    })
    setLoading(false);
    setDisableSubmit(false)
  };

  const theme = useTheme();
  const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
  let sx;
  if (mobileView) {
    sx = {
      mt: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  } else {
    sx = {
      mt: 1,
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

  const requestForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={sx}
    >
      <TextField
        type="number"
        margin="normal"
        required
        fullWidth
        id="quantity"
        label="Quantity"
        name="quantity"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetQuantity(e.target.value)}
        error={quantityErr}
        helperText={quantityErrText}
      />
      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disableSubmit}
      >
        {
          loading ? (
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
          ) : (
            <span>Submit</span>
          )
        }
      </Button>
    </Box>

  )

  return (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
              }}
            >
                <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                  <SettingsSharpIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Generate Stickers
                </Typography>

              {
                success ? (
                  <Typography variant='h4' color={green.A700}>{quantity} stickers generated successfully!</Typography>
                ) : (
                  requestForm
                )
              }
            </Box>
            <Copyright />
        </Container>
    </ThemeProvider>
  );
}

export default GenerateSticker