import { axiosPrivate } from '../api/axios'
import useRefreshToken from "./useRefreshToken";
import {useAuthenticationStore} from "../stores/useAuthenticationStore";
import {useEffect} from "react";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const authState = useAuthenticationStore((state) => state.authState)

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config: any) => {
        if (!config?.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${authState.jwtToken}`;
        }
        return config;
      },
      error => Promise.reject(error)
    )

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      response => response,
      async (err: any) => {
        const prevRequest = err?.config;
        if (err?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newJwtToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newJwtToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(err);
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    }
  }, [authState, refresh])

  return axiosPrivate
}

export default useAxiosPrivate;