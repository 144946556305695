import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import React, {useEffect, useState} from "react";
import {Statistics} from "../../api/responses";
import {getDashboardStatistics} from "../../api/brand";
import {Backdrop, CircularProgress, colors, Container} from "@mui/material";
import CustomBarChart from "../../components/brand/CustomBarChart";
import {useNotificationStore} from "../../stores/useNotificationStore";

const BrandDashboard = () => {
  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [statistics, setStatistics] = useState<Statistics[]>([])

  const sendNotification = useNotificationStore(state => state.sendNotification)

  useEffect(() => {
    setLoading(true)
    getDashboardStatistics(axios).then(response => {
      if (response.result) {
        setStatistics(response.data)
        setSuccess(true)
        setLoading(false)
      } else {
        setSuccess(false)
        setLoading(false)
        sendNotification('error', response.message)
      }
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Container component="main" maxWidth="md" sx={{
      marginTop: 8,
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      { success && (<CustomBarChart data={statistics}/>)}
    </Container>
  )
}

export default BrandDashboard;