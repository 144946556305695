enum RoleConstants {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_BRAND = 'ROLE_BRAND'
}

enum StickerStatus {
  INITIAL = "INITIAL",
  TO_PRINT = "TO_PRINT",
  PRINTED = "PRINTED",
  LOST = "LOST",
  ASSIGNED = "ASSIGNED",
  CONNECTED = "CONNECTED",
  SCANNED = "SCANNED"
}

export {RoleConstants, StickerStatus}