import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthenticationStore } from "../stores/useAuthenticationStore";
import { isEmpty } from "../utils/stringUtils";

const RequireGuestUser = () => {
  const authState = useAuthenticationStore((state) => state.authState);

  const isGuestUser = () => {
    return isEmpty(authState.userId);
  };

  return <>{isGuestUser() ? <Outlet /> : <Navigate to="/" replace />}</>;
};

export default RequireGuestUser;
