import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {updateStickerStatus} from "../../api/admin";
import {
  Box,
  Button,
  CircularProgress,
  FormControl, FormHelperText, InputLabel, Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import {green} from "@mui/material/colors";
import Copyright from "../Copyright";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {StickerStatus} from "../../constants/constants";
import MenuItem from "@mui/material/MenuItem";

const UpdateStickerStatus = () => {
  const [startingVisibleId, setStartingVisibleId] = useState<number>(0)
  const [startingVisibleIdErr, setStartingVisibleIdErr] = useState<boolean>(false)
  const [startingVisibleIdErrText, setStartingVisibleIdErrText] = useState<string>('')

  const [endingVisibleId, setEndingVisibleId] = useState<number>(0)
  const [endingVisibleIdErr, setEndingVisibleIdErr] = useState<boolean>(false)
  const [endingVisibleIdErrText, setEndingVisibleIdErrText] = useState<string>('')

  const [previousStatus, setPreviousStatus] = useState<string>('')
  const [previousStatusErr, setPreviousStatusErr] = useState<boolean>(false)
  const [previousStatusErrText, setPreviousStatusErrText] = useState<string>('')

  const [currentStatus, setCurrentStatus] = useState<string>('')
  const [currentStatusErr, setCurrentStatusErr] = useState<boolean>(false)
  const [currentStatusErrText, setCurrentStatusErrText] = useState<string>('')

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("")


  const axios = useAxiosPrivate()
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  const handleSetStartingVisibleId = (val: string) => {
    const startingVId = Number(val)
    if (isNaN(startingVId)) {
      setStartingVisibleIdErr(true)
      setStartingVisibleIdErrText("Starting visible id must be a number!")
      return
    }

    setStartingVisibleId(startingVId)
  }

  const handleSetEndingVisibleId = (val: string) => {
    const endingVid = Number(val)
    if (isNaN(endingVid)) {
      setEndingVisibleIdErr(true)
      setEndingVisibleIdErrText("Ending visible id must be a number!")
      return
    }

    setEndingVisibleId(endingVid)
  }

  const handleSetPreviousStatus = (val: string) => {
    if (!val) {
      setPreviousStatusErr(true)
      setPreviousStatusErrText("Previous Status can not be null")
      return
    }

    setPreviousStatus(val)
  }

  const handleSetCurrentStatus = (val: string) => {
    if (!val) {
      setCurrentStatusErr(true)
      setCurrentStatusErrText("Current Status can not be null")
      return
    }

    setCurrentStatus(val)
  }

  useEffect(() => {
    if (startingVisibleId <= 0) {
      setStartingVisibleIdErr(true)
      setStartingVisibleIdErrText("Starting visible id must be greater than zero!")
      return
    } else {
      setStartingVisibleIdErr(false)
      setStartingVisibleIdErrText("")
    }

    if (endingVisibleId <= 0) {
      setEndingVisibleIdErr(true)
      setEndingVisibleIdErrText("Ending visible id must be greater than zero!")
      return
    } else {
      setEndingVisibleIdErr(false)
      setEndingVisibleIdErrText("")
    }

    if (startingVisibleId > endingVisibleId) {
      setEndingVisibleIdErr(true);
      setEndingVisibleIdErrText("Ending visible id can not be smaller than starting visible id!");
      return
    } else {
      setEndingVisibleIdErr(false);
      setEndingVisibleIdErrText("");
    }

    if (!previousStatus) {
      setPreviousStatusErr(true)
      setPreviousStatusErrText("Previous Status can not be null");
      return
    } else {
      setPreviousStatusErr(false)
      setPreviousStatusErrText("");
    }

    if (!currentStatus) {
      setCurrentStatusErr(true)
      setCurrentStatusErrText("Current Status can not be null");
      return
    } else {
      setCurrentStatusErr(false)
      setCurrentStatusErrText("");
    }

    if (previousStatus === currentStatus) {
      setCurrentStatusErr(true)
      setCurrentStatusErrText("Previous Status and Current Status can not be same");
      return
    } else {
      setCurrentStatusErr(false)
      setCurrentStatusErrText("");
    }
  }, [startingVisibleId, endingVisibleId, previousStatus, currentStatus])


  useEffect(() => {
    if (startingVisibleIdErr || endingVisibleIdErr || previousStatusErr|| currentStatusErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [startingVisibleIdErr, endingVisibleIdErr, previousStatusErr, currentStatusErr])


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableSubmit(true)

    if (startingVisibleId <= 0) {
      setStartingVisibleIdErr(true);
      setStartingVisibleIdErrText("Starting visible id can not be less than zero!");
    }

    if (startingVisibleId <= 0) {
      setEndingVisibleIdErr(true);
      setEndingVisibleIdErrText("Ending visible id can not be less than zero!");
    }

    if (startingVisibleId > endingVisibleId) {
      setStartingVisibleIdErr(true);
      setStartingVisibleIdErrText("Starting visible id can not be greater than ending visible id!");

      setEndingVisibleIdErr(true);
      setEndingVisibleIdErrText("Ending visible id can not be smaller than ending visible id!");
    }

    if (startingVisibleId <= 0 || endingVisibleId <= 0 ||  startingVisibleId > endingVisibleId) {
      return;
    }

    setLoading(true)
    updateStickerStatus(axios, {
      starting_visible_id: startingVisibleId,
      ending_visible_id: endingVisibleId,
      previous_status: previousStatus,
      current_status: currentStatus,
    })
      .then(response => {
        if (response.result) {
          setSuccess(true);
          setSuccessMessage(response.message)
        } else {
          setSuccess(false)
          sendNotification('error', response.message)
        }
      })
    setLoading(false);
    setDisableSubmit(false)
  };

  const theme = useTheme();
  const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
  let sx;
  if (mobileView) {
    sx = {
      mt: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  } else {
    sx = {
      mt: 1,
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

  const requestForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={sx}
    >

      <TextField
        type="number"
        margin="normal"
        required
        fullWidth
        id="starting-visible-id"
        label="Starting Visible Id"
        name="starting-visible-id"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetStartingVisibleId(e.target.value)}
        error={startingVisibleIdErr}
        helperText={startingVisibleIdErrText}
      />

      <TextField
        type="number"
        margin="normal"
        required
        fullWidth
        id="ending-visible-id"
        label="Ending Visible Id"
        name="ending-visible-id"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetEndingVisibleId(e.target.value)}
        error={endingVisibleIdErr}
        helperText={endingVisibleIdErrText}
      />

      <FormControl
        error={previousStatusErr}
        fullWidth
        margin="normal"
      >
        <InputLabel id="previous-status">Previous Status</InputLabel>
        <Select
          labelId="previous-status"
          id="input-previous-status"
          value={previousStatus}
          label="Previous Status"
          onChange={(event) => handleSetPreviousStatus(event.target.value)}
        >
          <MenuItem value="" disabled selected><em>Choose One</em></MenuItem>
          <MenuItem value={StickerStatus.INITIAL}>{StickerStatus.INITIAL}</MenuItem>
          <MenuItem value={StickerStatus.TO_PRINT}>{StickerStatus.TO_PRINT}</MenuItem>
        </Select>

        {
          previousStatusErr && <FormHelperText>{previousStatusErrText}</FormHelperText>
        }
      </FormControl>

      <FormControl
        error={currentStatusErr}
        fullWidth
        margin="normal"
      >
        <InputLabel id="previous-status">Current Status</InputLabel>
        <Select
          labelId="current-status"
          id="input-current-status"
          value={currentStatus}
          label="Current Status"
          onChange={(event) => handleSetCurrentStatus(event.target.value)}
        >
          <MenuItem value="" disabled selected><em>Choose One</em></MenuItem>
          <MenuItem value={StickerStatus.TO_PRINT}>{StickerStatus.TO_PRINT}</MenuItem>
          <MenuItem value={StickerStatus.PRINTED}>{StickerStatus.PRINTED}</MenuItem>
        </Select>

        {
          currentStatusErr && <FormHelperText>{currentStatusErrText}</FormHelperText>
        }
      </FormControl>

      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disableSubmit}
      >
        {
          loading ? (
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
          ) : (
            <span>Submit</span>
          )
        }
      </Button>
    </Box>
  )

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <PublishedWithChangesIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update Sticker Status
          </Typography>

          {
            success ? (
              <Typography variant='h4' color={green.A700}>{successMessage}</Typography>
            ) : (
              requestForm
            )
          }
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default UpdateStickerStatus;