import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import About from "./app/pages/About";
import Home from "./app/pages/Home";
import NotFound from "./app/pages/NotFound";
import RequireSignInUser from "./app/components/RequireAuth";
import RequireGuestUser from "./app/components/RequireGuestUser";
import Unauthorized from "./app/pages/Unauthorized";
import SignIn from "./app/pages/user/SignIn";
import SignOut from "./app/pages/user/SignOut";
import SignUp from "./app/pages/user/SignUp";
import AdminHome from "./app/pages/admin/AdminHome";
import CreateBrand from "./app/pages/admin/CreateBrand";
import AssignBrandToUser from "./app/pages/admin/AssignBrandToUser";
import AssignBrandToSticker from "./app/pages/admin/AssignBrandToSticker";
import GenerateSticker from "./app/pages/admin/GenerateSticker";
import SuperAdminHome from "./app/pages/admin/superadmin/SuperAdminHome";
import UserManagement from "./app/pages/admin/superadmin/UserManagement";
import EditUser from "./app/pages/admin/superadmin/EditUser";
import {RoleConstants} from './app/constants/constants'
import BrandHome from "./app/pages/brand/BrandHome";
import ConnectProduct from "./app/pages/brand/connectproduct/ConnectProduct";
import BrandManagement from "./app/pages/admin/BrandManagement";
import EditBrand from "./app/pages/admin/EditBrand";
import BrandDashboard from "./app/pages/brand/BrandDashboard";
import AddProduct from "./app/pages/brand/AddProduct";
import MUIClippedDrawer from "./app/components/MUIClippedDrawer";
import { Box } from "@mui/material";
import DemoStickerPrint from "./app/pages/admin/DemoStickerPrint";
import DemoStickerPrintedPage from "./app/pages/admin/DemoStickerPrintedPage";
import ConnectProductHome from "./app/pages/brand/connectproduct/ConnectProductHome";
import UpdateConnectedProduct from "./app/pages/brand/connectproduct/UpdateConnectedProduct";
import ConnectMultipleProduct from "./app/pages/brand/connectproduct/ConnectMultipleProduct";
import UpdateMultipleProduct from "./app/pages/brand/connectproduct/UpdateMultipleProduct";
import UpdateStickerStatus from "./app/pages/admin/UpdateStickerStatus";
import UpdateStickerStatusToLost from "./app/pages/admin/UpdateStickerStatusToLost";
import AdminDashboard from "./app/pages/admin/AdminDashboard";
import AdminDashboardDetails from "./app/pages/admin/AdminDashboardDetails";

function App() {
  return (
    <Box component="main" className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MUIClippedDrawer />}>
            <Route element={<RequireGuestUser />}>
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
            </Route>

            <Route element={<RequireSignInUser allowedRoles={[RoleConstants.ROLE_USER]}/>}>
              <Route path="" element={<Home />} />

              <Route path="admin" element={<RequireSignInUser allowedRoles={[RoleConstants.ROLE_ADMIN]}/>}>
                <Route path="" element={<AdminHome />} />
                <Route path="dashboard" element={<AdminDashboard/>} />
                <Route path="stickers/generate" element={<GenerateSticker />} />
                <Route path="stickers/print" element={<DemoStickerPrint/>} />
                <Route path="stickers/assign-brand" element={<AssignBrandToSticker />} />
                <Route path="stickers/update-status" element={<UpdateStickerStatus />} />
                <Route path="stickers/update-status-to-lost" element={<UpdateStickerStatusToLost />} />
                <Route path="brand-management" element={<BrandManagement />} />
                <Route path="edit-brand/:id" element={<EditBrand />} />
                <Route path="brands/create" element={<CreateBrand />} />
                <Route path="users/assign-brand" element={<AssignBrandToUser />} />

                <Route path="super-admin" element={<RequireSignInUser allowedRoles={[RoleConstants.ROLE_SUPER_ADMIN]} />}>
                  <Route path="" element={<SuperAdminHome />} />
                  <Route path="user-management" element={<UserManagement />} />
                  <Route path="edit-user/:id" element={<EditUser />} />
                </Route>
              </Route>

              <Route path="brand" element={<RequireSignInUser allowedRoles={[RoleConstants.ROLE_BRAND]}/>}>
                <Route path="" element={<BrandHome />}/>
                <Route path="dashboard" element={<BrandDashboard />}/>
                <Route path="add-product" element={<AddProduct/>}/>
                <Route path="connect-product" element={<ConnectProductHome />}/>
                <Route path="connect-product/connect-single-sticker" element={<ConnectProduct />}/>
                <Route path="connect-product/connect-multiple-sticker" element={<ConnectMultipleProduct/>}/>
                <Route path="connect-product/update-single-sticker" element={<UpdateConnectedProduct/>}/>
                <Route path="connect-product/update-multiple-sticker" element={<UpdateMultipleProduct/>}/>
              </Route>

              <Route path="signout" element={<SignOut />} />
            </Route>


            <Route path="about" element={<About />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          {/*Without header and side nav bar routing*/}
          <Route element={<RequireSignInUser allowedRoles={[RoleConstants.ROLE_ADMIN]}/>}>
            <Route path="/admin/stickers/printed-page" element={<DemoStickerPrintedPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
