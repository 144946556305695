import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {getRequiredMessage} from "../../constants/errormessages";
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {green} from "@mui/material/colors";
import Copyright from "../Copyright";
import {isEmpty} from "../../utils/stringUtils";
import {assignBrandToUser} from "../../api/admin";
import {validateEmail} from "../../utils/validators";
import BrandAutoComplete from "../../components/admin/BrandAutoComplete";

const AssignBrandToUser = () => {
    const [brandId, setBrandId] = useState<string>("")
    const [brandIdErr, setBrandIdErr] = useState<boolean>(false)

    const [userEmail, setUserEmail] = useState<string>("")
    const [userEmailErr, setUserEmailErr] = useState<boolean>(false)
    const [userEmailErrText, setUserEmailErrText] = useState<string>("")

    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const axios = useAxiosPrivate()
    const sendNotification = useNotificationStore((state) => state.sendNotification)

    const handleSetUserEmail = (val : string) => {
      setUserEmail(val);
      if (!validateEmail(val)) {
        setUserEmailErr(true);
        setUserEmailErrText("Invalid email format!");
        return;
      } else {
        setUserEmailErr(false);
        setUserEmailErrText("");
        return;
      }
    }

    useEffect(() => {
        if (brandIdErr || userEmailErr) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [brandIdErr, userEmailErr])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisableSubmit(true)

        if (isEmpty(brandId)) {
            setBrandIdErr(true);
        }

        if (isEmpty(userEmail)) {
            setUserEmailErr(true);
            setUserEmailErrText(getRequiredMessage("User"));
        }

        if (isEmpty(brandId) || isEmpty(userEmail) || brandIdErr || userEmailErr) {
            return;
        }

        setLoading(true)
        assignBrandToUser(axios, {brand_id: brandId, user_email: userEmail})
          .then(response => {
              if (response.result) {
                  setSuccess(true);
              } else {
                  setSuccess(false);
                  sendNotification('error', response.message);
              }}
          )

        setLoading(false);
        setDisableSubmit(false)
    };

    const theme = useTheme();
    const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
    let sx;
    if (mobileView) {
        sx = {
            mt: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center'
        }
    } else {
        sx = {
            mt: 1,
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center'
        }
    }

    const requestForm = (
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={sx}
      >
        <BrandAutoComplete setBrandIdHandler={setBrandId} setBrandIdErrHandler={setBrandIdErr} />
          <TextField
            type="text"
            margin="normal"
            required
            fullWidth
            id="user-email"
            label="User Email"
            name="user-email"
            autoComplete="off"
            autoFocus
            onChange={(e) => handleSetUserEmail(e.target.value)}
            error={userEmailErr}
            helperText={userEmailErrText}
          />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={disableSubmit}
          >
              {
                  loading ? (
                    <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
                  ) : (
                    <span>Submit</span>
                  )
              }
          </Button>
      </Box>

    )

    return (
      <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
              <CssBaseline />
              <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
              >
                  <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                      <AssignmentIndIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                      Assign Brand To User
                  </Typography>

                  {
                      success ? (
                        <Typography variant='h4' color={green.A700}>Brand assigned successfully!</Typography>
                      ) : (
                        requestForm
                      )
                  }
              </Box>
              <Copyright />
          </Container>
      </ThemeProvider>
    );
}

export default AssignBrandToUser