import {AuthenticationState} from "../stores/useAuthenticationStore";
import {RoleConstants} from "../constants/constants";

export const isLoggedIn = (authState: AuthenticationState) => {
  return authState?.userId !== '';
}

export const isNormalUser = (authState: AuthenticationState) => {
  return authState?.roles.length === 1 && authState?.roles[0] === RoleConstants.ROLE_USER;
}

export const isBrandUser = (authState: AuthenticationState) => {
  return authState?.roles?.find(role => role === RoleConstants.ROLE_BRAND);
}

export const isAdminUser = (authState: AuthenticationState) => {
  return authState?.roles?.find(role => role === RoleConstants.ROLE_ADMIN);
}

export const isSuperAdminUser = (authState: AuthenticationState) => {
  return authState?.roles?.find(role => role === RoleConstants.ROLE_SUPER_ADMIN);
}
