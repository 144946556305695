import React, {Dispatch, SetStateAction, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {
    Box,
    Button, CircularProgress,
    FormControl, Grid,
    InputLabel,
    Select,
    TextField, Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {StickerStatus} from "../../constants/constants";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {green} from "@mui/material/colors";
import Copyright from "../../pages/Copyright";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {StickerDetailsReportFilter} from "../../api/requests";

interface DetailsFilterProps {
    filter: Dispatch<SetStateAction<StickerDetailsReportFilter>>
    handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

const AdminDashboardDetailsFilter = ({filter, handleFormSubmit}: DetailsFilterProps) => {
    const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

    const [status, setStatus] = useState<string>('')
    const [brandId, setBrandId] = useState<string>('')
    const [createdAtFrom, setCreatedAtFrom] = useState<Dayjs| null>(dayjs())
    const [createdAtTo, setCreatedAtTo] = useState<Dayjs | null>(dayjs())
    const [updatedAtFrom, setUpdatedAtFrom] = useState<Dayjs | null>(dayjs())
    const [updatedAtTo, setUpdatedAtTo] = useState<Dayjs| null>(dayjs())

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("")


    const axios = useAxiosPrivate()
    const sendNotification = useNotificationStore((state) => state.sendNotification)

    const resetFilter = () => {
        setStatus('')
        setBrandId('')
        setCreatedAtFrom(null)
        setCreatedAtTo(null)
        setUpdatedAtFrom(null)
        setUpdatedAtTo(null)
        filter({})
    }

    const handleSetStatus = (status: string) => {
        setStatus(status);
        filter(prevState => {
            return {
                ...prevState,
                status: status
            }
        })
    }

    const handleSetBrandId = (brandId: string) => {
        setBrandId(brandId)
        filter(prevState => {
            return {
                ...prevState,
                brand_id: brandId
            }
        })
    }

    const handleSetCreatedAtFrom = (createdAtFrom: Dayjs | null) => {
        setCreatedAtFrom(createdAtFrom)
        if (createdAtFrom) {
            filter(prevState => {
                return {
                    ...prevState,
                    created_at_from: createdAtFrom.format(DATE_TIME_FORMAT)
                }
            })
        }
    }

    const handleSetCreatedAtTo = (createdAtTo: Dayjs | null) => {
        setCreatedAtTo(createdAtTo)
        if (createdAtTo) {
            filter(prevState => {
                return {
                    ...prevState,
                    created_at_to: createdAtTo.format(DATE_TIME_FORMAT)
                }
            })
        }
    }

    const handleSetUpdatedAtFrom = (updatedAtFrom: Dayjs | null) => {
        setUpdatedAtFrom(updatedAtFrom)
        if (updatedAtFrom) {
            filter(prevState => {
                return {
                    ...prevState,
                    updated_at_from: updatedAtFrom.format(DATE_TIME_FORMAT)
                }
            })
        }
    }

    const handleSetUpdatedAtTo = (updatedAtTo: Dayjs | null) => {
        setUpdatedAtTo(updatedAtTo)
        if (updatedAtTo) {
            filter(prevState => {
                return {
                    ...prevState,
                    updated_at_to: updatedAtTo.format(DATE_TIME_FORMAT)
                }
            })
        }
    }

    const theme = useTheme();
    const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
    let sx;
    if (mobileView) {
        sx = {
            mt: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center'
        }
    } else {
        sx = {
            mt: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'center',
            alignItems: 'center'
        }
    }

    const requestForm = (
        <Box
            component="form"
            onSubmit={handleFormSubmit}
            noValidate
            sx={sx}
            gap={1}
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <FormControl
                        fullWidth
                        margin="normal"
                        sx={{
                            width: '40'
                        }}
                    >
                        <InputLabel id="previous-status">Status</InputLabel>
                        <Select
                            labelId="status"
                            id="status"
                            value={status}
                            label="Status"
                            onChange={(e) => handleSetStatus(e.target.value)}
                        >
                            <MenuItem value="" disabled selected><em>Choose One</em></MenuItem>
                            {
                                Object.keys(StickerStatus).map(value => <MenuItem key={`status-select-${value}`} value={value}>{value}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        type="text"
                        margin="normal"
                        id="brand-id"
                        label="Brand Id"
                        name="brand_id"
                        autoComplete="off"
                        value={brandId}
                        autoFocus
                        onChange={(e) => handleSetBrandId(e.target.value)}
                    />
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={3}>
                            <DateTimePicker
                                key="created-at-from"
                                label="CreatedAt From"
                                ampm={false}
                                format="YYYY-MM-DD HH:mm:ss"
                                value={createdAtFrom}
                                onChange={(e: Dayjs | null) => handleSetCreatedAtFrom(e)}
                            />
                    </Grid>
                    <Grid item xs={3}>
                        <DateTimePicker
                            key="created-at-to"
                            label="CreatedAt To"
                            ampm={false}
                            format="YYYY-MM-DD HH:mm:ss"
                            value={createdAtTo}
                            onChange={(e: Dayjs | null) => handleSetCreatedAtTo(e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DateTimePicker
                            key="updated-at-from"
                            label="UpdatedAt From"
                            ampm={false}
                            format="YYYY-MM-DD HH:mm:ss"
                            value={updatedAtFrom}
                            onChange={(e: Dayjs | null) => handleSetUpdatedAtFrom(e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DateTimePicker
                            key="updated-at-to"
                            label="UpdatedAt To"
                            ampm={false}
                            format="YYYY-MM-DD HH:mm:ss"
                            value={updatedAtTo}
                            onChange={(e: Dayjs | null) => handleSetUpdatedAtTo(e)}
                        />
                    </Grid>
                </LocalizationProvider>

                <Grid item xs={12}>
                    <Container sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2
                    }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {
                                loading ? (
                                    <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
                                ) : (
                                    <span>Submit</span>
                                )
                            }
                        </Button>

                        <Button
                            type="button"
                            variant="contained"
                            color="warning"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => resetFilter()}
                        >
                            <span>Reset</span>
                        </Button>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                        <FilterAltOffIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Details Report Filters
                    </Typography>

                    {
                        success ? (
                            <Typography variant='h4' color={green.A700}>{successMessage}</Typography>
                        ) : (
                            requestForm
                        )
                    }
                </Box>
                <Copyright />
            </Container>
        </ThemeProvider>
    );
}

export default AdminDashboardDetailsFilter;