import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {isEmpty} from "../../utils/stringUtils";
import {getRequiredMessage} from "../../constants/errormessages";
import {Box, Button, CircularProgress, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {green} from "@mui/material/colors";
import Copyright from "../Copyright";
import {addProduct} from "../../api/brand";
import DragDropImageUpload from "../../components/image-upload/DragDropImageUpload"

const AddProduct = () => {

  const [productName, setProductName] = useState<string>("")
  const [productNameErr, setProductNameErr] = useState<boolean>(false)
  const [productNameErrText, setProductNameErrText] = useState<string>("")

  const [productType, setProductType] = useState<string>("")
  const [productTypeErr, setProductTypeErr] = useState<boolean>(false)
  const [productTypeErrText, setProductTypeErrText] = useState<string>("")

  const [modelName, setModelName] = useState<string>("")
  const [modelNameErr, setModelNameErr] = useState<boolean>(false)
  const [modelNameErrText, setModelNameErrText] = useState<string>("")

  const [authorName, setAuthorName] = useState<string>("")
  const [authorNameErr, setAuthorNameErr] = useState<boolean>(false)
  const [authorNameErrText, setAuthorNameErrText] = useState<string>("")

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const axios = useAxiosPrivate()
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  useEffect(() => {
    if (productNameErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [productNameErr])

  const handleSetProductName = (val : string) => {
    setProductName(val)
    if (isEmpty(val)) {
      setProductNameErr(true)
      setProductNameErrText(getRequiredMessage("Product Name"))
      return
    }
    if (val.length < 3) {
      setProductNameErr(true)
      setProductNameErrText("Product Name must be greater than 2 character!")
      return
    }

    setProductNameErr(false)
    setProductNameErrText("")
  }

  const handleChangeProductType = (event: SelectChangeEvent) => {
    setProductType(event.target.value as string);
    if (!(event.target.value as string)) {
      setProductTypeErr(true)
      setProductTypeErrText("Product Type is required!")
      return
    }
    setProductTypeErr(false)
    setProductTypeErrText("")
  }

  const handleSetModelName = (val : string) => {
    setModelName(val)
    if (val.length < 3) {
      setModelNameErr(true)
      setModelNameErrText("Model Name should be greater than 2 character!")
      return
    }

    setModelNameErr(false)
    setModelNameErrText("")
  }

  const handleSetAuthorName = (val : string) => {
    setAuthorName(val)
    if (val.length < 3) {
      setAuthorNameErr(true)
      setAuthorNameErrText("Author Name should be greater than 2 character!")
      return
    }

    setAuthorNameErr(false)
    setAuthorNameErrText("")
  }

  const handleFileUpload = (file: File) => {
    setUploadedFile(file);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableSubmit(true)

    if (isEmpty(productName)) {
      setProductNameErr(true);
      setProductNameErrText(getRequiredMessage("Product Name"));
    }

    if (isEmpty(productType)) {
      setProductTypeErr(true);
      setProductTypeErrText("Product Type is required!")
    }

    if (productNameErr || isEmpty(productName) || productTypeErr || isEmpty(productType)) {
      return;
    }

    setLoading(true);

    addProduct(axios, 
      {
        product_name: productName,
        product_type: productType,
        model_name: modelName,
        author_name: authorName,
        product_image: uploadedFile
      })
      .then(response => {
        if (response.result) {
          setSuccess(true)
        } else {
          setSuccess(false)
          sendNotification('error', response.message);
        }
      })

    setLoading(false);
    setDisableSubmit(false)
  };

  const theme = useTheme();
  const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
  let sx;
  if (mobileView) {
    sx = {
      mt: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  } else {
    sx = {
      mt: 1,
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

  const requestForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={sx}
    >

      <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="product-name"
        label="Product Name"
        name="product-name"
        autoComplete="off"
        onChange={(e) => handleSetProductName(e.target.value)}
        error={productNameErr}
        helperText={productNameErrText}
      />

    <FormControl sx={{mt: 2}} fullWidth required error={productTypeErr}>
      <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="product-type"
          required
          fullWidth
          label="Product Type"
          name="product-type"
          autoComplete="off"
          onChange={handleChangeProductType}
        >
          <MenuItem value={"BOOK"}>BOOK</MenuItem>
          <MenuItem value={"ELECTRONICS"}>ELECTRONICS</MenuItem>
          <MenuItem value={"PRODUCT"}>PRODUCT</MenuItem>
          <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
        </Select>
        <FormHelperText>{productTypeErrText}</FormHelperText>
    </FormControl>

      <TextField
        type="text"
        margin="normal"
        fullWidth
        id="model-name"
        label="Model Name"
        name="model-name"
        autoComplete="off"
        onChange={(e) => handleSetModelName(e.target.value)}
        error={modelNameErr}
        helperText={modelNameErrText}
      />

      <TextField
        type="text"
        margin="normal"
        fullWidth
        id="author-name"
        label="Author Name"
        name="author-name"
        autoComplete="off"
        onChange={(e) => handleSetAuthorName(e.target.value)}
        error={authorNameErr}
        helperText={authorNameErrText}
      />

      <DragDropImageUpload onFileDrop={handleFileUpload} />

      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disableSubmit}
      >
        {
          loading ? (
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
          ) : (
            <span>Submit</span>
          )
        }
      </Button>
    </Box>

  )

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <NoteAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Add Product's Info
          </Typography>

          {
            success ? (
              <Typography variant='h4' color={green.A700}> Add Product info Inserted!</Typography>
            ) : (
              requestForm
            )
          }
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default AddProduct;