import { useBarcode } from "next-barcode";

interface VisibleBarCodeProps {
  value: string;
}

const VisibleBarCode = ({ value }: VisibleBarCodeProps) => {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      format: "CODE128",
      width: 0.67,
      height: 20,
      fontSize: 10,
      flat: true,
    },
  });
  return <svg ref={inputRef} />;
};

export default VisibleBarCode;
