import {LoginRequest, RegisterNewAccountRequest} from "./requests";
import {Response, LoginResponse} from "./responses";
import {Axios} from "axios";

export const login = async (axios: Axios, request: LoginRequest): Promise<LoginResponse> => {
    const abortController = new AbortController();
    return axios.post<LoginResponse>('/login', request, {
        headers: {'Content-Type': 'application/json'},
        signal: abortController.signal
    })
        .then(response => {
            return {result: true, message: 'Login successful!', token: response.data.token}
        }).catch(err => {
            if (err.toJSON().code === 'ERR_NETWORK') {
                return {result: false, message: 'No internet connection'}
            }
            if (err.response.status === 400) {
                return err.response.data
            }
            return {result: false, message: 'Something went wrong! Please try again!'}
        }).finally(() => {
            abortController.abort();
        })
}

export const logout = async (axios: Axios): Promise<Response> => {
    const abortController = new AbortController();
    return axios.post<Response>('/logout', {}, {
        signal: abortController.signal
    })
        .then(() => {
            return {result: true, message: 'Logout successful!'}
        }).catch(err => {
            if (err.toJSON().code === 'ERR_NETWORK') {
                return {result: false, message: 'No internet connection'}
            }
            return {result: false, message: 'Something went wrong! Please try again!'}
        }).finally(() => {
            abortController.abort();
        })
}


export const register = async (axios: Axios, request: RegisterNewAccountRequest): Promise<Response> => {
    const abortController = new AbortController();
    return axios.post<Response>("/register", JSON.stringify(request), {
        headers: {"Content-Type": "application/json"},
        signal: abortController.signal
    }).then(response => {
        return response.data
    }).catch(err => {
        if (err.toJSON().code === 'ERR_NETWORK') {
            return {result: false, message: 'No internet connection'}
        }
        if (err.response.status === 400) {
            return err.response.data
        }
        return {result: false, message: 'Error occurred during registration! Please try again!'}
    }).finally(() => {
        abortController.abort();
    })
}
