import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, TablePagination} from "@mui/material";
import {Dispatch, SetStateAction} from "react";
import {User} from "../../../model";
import RolePill from "./RolePill";
import {useNavigate} from "react-router-dom";
import {SUPER_ADMIN_EDIT_USER_URL} from "../../../constants/urls";
import NoDataFound from "../../NoDataFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#a7a7a761',
    color: 'black',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface UserListProps {
  users: User[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  perPage: number
  setPerPage: Dispatch<SetStateAction<number>>
  totalUsers: number
}

export default function UsersList({users, page, perPage, totalUsers, setPage, setPerPage}: UserListProps) {
  const navigate = useNavigate()
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!users) {
    return <NoDataFound />
  }

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>SL</StyledTableCell>
            <StyledTableCell align="right">Id</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Email</StyledTableCell>
            <StyledTableCell align="right">Phone</StyledTableCell>
            <StyledTableCell align="right">Roles</StyledTableCell>
            <StyledTableCell align="right">BrandId</StyledTableCell>
            <StyledTableCell align="right">CreatedAt</StyledTableCell>
            <StyledTableCell align="right">UpdatedAt</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <StyledTableRow key={user.id}>
              <StyledTableCell align="right">{(page * perPage) + index + 1}</StyledTableCell>
              <StyledTableCell align="right">{user.id}</StyledTableCell>
              <StyledTableCell align="right">{user.name}</StyledTableCell>
              <StyledTableCell align="right">{user.email}</StyledTableCell>
              <StyledTableCell align="right">{user.phone}</StyledTableCell>
              <StyledTableCell align="right">{
                user.roles.map(role => <RolePill key={role} role={role} />)
              }</StyledTableCell>
              <StyledTableCell align="right">{user.brand_id}</StyledTableCell>
              <StyledTableCell align="right">{user.created_at}</StyledTableCell>
              <StyledTableCell align="right">{user.updated_at}</StyledTableCell>
              <StyledTableCell align="right">
                <Button onClick={() => navigate(`${SUPER_ADMIN_EDIT_USER_URL}/${user.id}`)}>Edit</Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  <TablePagination
    rowsPerPageOptions={[5, 10, 25, 50]}
    component="div"
    count={totalUsers}
    rowsPerPage={perPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
    </>
  );
}
