import jwtDecode from "jwt-decode";
import create from "zustand";
import { persist } from "zustand/middleware";

export interface AuthenticationState {
  jwtToken: string;
  roles: string[];
  userId: string;
  email: string;
}

const initialState: AuthenticationState = {
  jwtToken: "",
  roles: [],
  userId: "",
  email: ""
};

interface AuthenticationStore {
  authState: AuthenticationState;
  setAuthentication: (token: string) => void;
  removeAuthentication: () => void;
}

interface JwtPayload {
  email: string;
  type: number;
  sub: string;
  roles: string[];
}

export const useAuthenticationStore = create<AuthenticationStore>()(
  persist((set) => ({
    authState: initialState,
    setAuthentication: async (token) => {
      const decodedToken = jwtDecode<JwtPayload>(token);
      set(() => ({ authState: {jwtToken: token, roles: decodedToken.roles, userId: decodedToken.sub, email: decodedToken.email}}));
    },
    removeAuthentication: () => {
      set(() => ({ authState: initialState }));
    },
  }))
);
