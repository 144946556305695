import OperationList from "../../../components/OperationList";
import {useNavigate} from "react-router-dom";
import {SUPER_ADMIN_USER_MANAGEMENT_URL} from "../../../constants/urls";

const SuperAdminHome = () => {
  const navigate = useNavigate();
  const operationList = [
    {
      title: 'User Management',
      onClick: () => navigate(SUPER_ADMIN_USER_MANAGEMENT_URL)
    }
  ]

  return (
    <OperationList operationList={operationList} />
  )
}

export default SuperAdminHome;