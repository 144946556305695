import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CableIcon from '@mui/icons-material/Cable';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import PrintIcon from '@mui/icons-material/Print';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReportIcon from '@mui/icons-material/Report';
import {Outlet, useNavigate} from "react-router-dom";
import Header from "./Header"
import Notification from "./Notification";
import {
  ADMIN_ASSIGN_BRAND_TO_STICKER_URL,
  ADMIN_ASSIGN_BRAND_TO_USER_URL,
  ADMIN_BRAND_MANAGEMENT_URL,
  ADMIN_CREATE_NEW_BRAND_URL,
  ADMIN_GENERATE_STICKER_URL,
  ADMIN_PRINT_STICKER_URL,
  ADMIN_UPDATE_STICKER_STATUS_URL,
  ADMIN_UPDATE_STICKER_STATUS_TO_LOST_URL,
  BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL,
  BRAND_ADD_PRODUCT_URL,
  BRAND_DASHBOARD_URL,
  SUPER_ADMIN_DASHBOARD_URL, ADMIN_DASHBOARD_URL
} from "../constants/urls";
import {isAdminUser, isBrandUser, isLoggedIn, isNormalUser, isSuperAdminUser} from "../utils/authUtils";
import {useAuthenticationStore} from "../stores/useAuthenticationStore";
import {Operation} from "../types/customtypes";
import {colors, Typography} from "@mui/material";

const drawerWidth = 240;

export default function MUIClippedDrawer() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [showMenuButton, setShowMenuButton] = useState<boolean>(false)
  const authState = useAuthenticationStore(state => state.authState)
  const [brandOperationList, setBrandOperationList] = useState<Operation[]>([])
  const [adminOperationList, setAdminOperationList] = useState<Operation[]>([])

  useEffect(()=> {
    setBrandOperationList([])
    setAdminOperationList([])
    if (isBrandUser(authState)) {
      let operations: Operation[] = [
        {
          title: 'Dashboard',
          icon: <DashboardIcon/>,
          navigateLink: BRAND_DASHBOARD_URL,
          isActive: false
        },
        {
          title: 'Connect Product Info With Sticker',
          icon: <CableIcon/>,
          navigateLink: BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL,
          isActive: false
        },
        {
          title: 'Add Product',
          icon: <AddBoxSharpIcon/>,
          navigateLink: BRAND_ADD_PRODUCT_URL,
          isActive: false
        },
      ];

      setBrandOperationList(operations);
    }

    let adminOperations: Operation[] = []
    if (isAdminUser(authState)) {
      adminOperations = [
        {
          title: 'Admin Dashboard',
          icon: <DashboardIcon/>,
          navigateLink: ADMIN_DASHBOARD_URL,
          isActive: false
        },
        {
          title: 'Generate Sticker',
          icon: <SettingsSharpIcon />,
          navigateLink: ADMIN_GENERATE_STICKER_URL,
          isActive: false
        },
        {
          title: 'Demo Sticker Print',
          icon: <PrintIcon />,
          navigateLink: ADMIN_PRINT_STICKER_URL,
          isActive: false
        },
        {
          title: 'Update Sticker Status',
          icon: <PublishedWithChangesIcon />,
          navigateLink: ADMIN_UPDATE_STICKER_STATUS_URL,
          isActive: false
        },
        {
          title: 'Update Sticker Status To Lost',
          icon: <ReportIcon color="error"/>,
          navigateLink: ADMIN_UPDATE_STICKER_STATUS_TO_LOST_URL,
          isActive: false
        },
        {
          title: 'Assign Brand To Sticker',
          icon: <LineStyleIcon />,
          navigateLink: ADMIN_ASSIGN_BRAND_TO_STICKER_URL,
          isActive: false
        },
        {
          title: 'Create New Brand',
          icon: <NoteAddIcon />,
          navigateLink: ADMIN_CREATE_NEW_BRAND_URL,
          isActive: false
        },
        {
          title: 'Brands',
          icon: <BusinessIcon />,
          navigateLink: ADMIN_BRAND_MANAGEMENT_URL,
          isActive: false
        },
        {
          title: 'Assign Brand To User',
          icon: <AssignmentIndIcon />,
          navigateLink: ADMIN_ASSIGN_BRAND_TO_USER_URL,
          isActive: false
        }
      ];

      setAdminOperationList(adminOperations)
    }

    if (isSuperAdminUser(authState)) {
      adminOperations.push({
        title: 'Super Admin',
        icon: <AdminPanelSettingsIcon />,
        navigateLink: SUPER_ADMIN_DASHBOARD_URL,
        isActive: false
      })
    }
    setAdminOperationList(adminOperations)
  }, [authState])

  const handleBrandMenuClick = (menuTitle: string, navigateLink: string) => {
    // resetting admin active menu
    setAdminOperationList(prevState => prevState.map(op => ({...op, isActive: false})))
    // setting brand active menu
    setBrandOperationList(prevState => prevState.map(op => op.title === menuTitle? {...op, isActive: true} : {...op, isActive: false}))
    navigate(navigateLink)
  }

  const handleAdminMenuClick = (menuTitle: string, navigateLink: string) => {
    // resetting brand active menu
    setBrandOperationList(prevState => prevState.map(op => ({...op, isActive: false})))
    // setting admin active menu
    setAdminOperationList(prevState => prevState.map(op => op.title === menuTitle? {...op, isActive: true} : {...op, isActive: false}))
    navigate(navigateLink)
  }

  useEffect(() => {
    if (!isNormalUser(authState) && isLoggedIn(authState)) {
      setOpenDrawer(true);
      setShowMenuButton(true)
    } else {
      setOpenDrawer(false)
      setShowMenuButton(false)
    }
  },[authState])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header showMenuButton={showMenuButton} handleSetOpenDrawer={setOpenDrawer}/>
      <Notification />
      <Drawer
        variant="persistent"
        open={openDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          ...(!openDrawer && { display: 'none' })
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {
              brandOperationList.length !== 0 && <Typography m={2}>Brand Operations</Typography>
            }
            {
              brandOperationList.map(op => {
                let backgroundColor = ''
                if (op.isActive) {
                  backgroundColor = colors.grey.A200
                }

                return (
                <ListItem sx={{backgroundColor: backgroundColor}}
                          key={op.title}
                          onClick={() => handleBrandMenuClick(op.title, op.navigateLink)}
                          disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {op.icon}
                    </ListItemIcon>
                    <ListItemText primary={op.title} />
                  </ListItemButton>
                </ListItem>
              )})
            }
          </List>

          <List>
            {
              adminOperationList.length !== 0 && <Typography m={2}>Admin Operations</Typography>
            }
            {
              adminOperationList.map(op => {
                let backgroundColor = ''
                if (op.isActive) {
                  backgroundColor = colors.grey.A200
                }

                return (
                  <ListItem sx={{backgroundColor: backgroundColor}}
                            key={op.title}
                            onClick={() => handleAdminMenuClick(op.title, op.navigateLink)}
                            disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        {op.icon}
                      </ListItemIcon>
                      <ListItemText primary={op.title} />
                    </ListItemButton>
                  </ListItem>
                )})
            }
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: '100vw', minHeight: '100vh' }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}