// brand
export const BRAND_URL = '/brand'
export const BRAND_DASHBOARD_URL = BRAND_URL + '/dashboard'
export const BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL = BRAND_URL +  '/connect-product'
export const BRAND_ADD_PRODUCT_URL = BRAND_URL +  '/add-product'
export const BRAND_PRODUCT_CONNECT_SINGLE_STICKER_URL = BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL  + '/connect-single-sticker'
export const BRAND_PRODUCT_CONNECT_MULTIPLE_STICKER_URL = BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL + '/connect-multiple-sticker'
export const BRAND_PRODUCT_UPDATE_SINGLE_STICKER_URL = BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL + '/update-single-sticker'
export const BRAND_PRODUCT_UPDATE_MULTIPLE_STICKER_URL = BRAND_CONNECT_PRODUCT_INFO_WITH_STICKER_HOME_URL + '/update-multiple-sticker'

// admin
export const ADMIN_URL = '/admin'
export const ADMIN_DASHBOARD_URL = ADMIN_URL + '/dashboard'
export const ADMIN_STICKER_URL = ADMIN_URL + '/stickers'
export const ADMIN_GENERATE_STICKER_URL = ADMIN_STICKER_URL +  '/generate'
export const ADMIN_PRINT_STICKER_URL = ADMIN_STICKER_URL + '/print'
export const ADMIN_UPDATE_STICKER_STATUS_URL = ADMIN_STICKER_URL + '/update-status'
export const ADMIN_UPDATE_STICKER_STATUS_TO_LOST_URL = ADMIN_STICKER_URL + '/update-status-to-lost'
export const ADMIN_PRINTED_STICKER_PAGE_URL = ADMIN_STICKER_URL + '/printed-page'
export const ADMIN_ASSIGN_BRAND_TO_STICKER_URL  = ADMIN_STICKER_URL + '/assign-brand'

export const ADMIN_BRAND_URL = ADMIN_URL + '/brands'
export const ADMIN_CREATE_NEW_BRAND_URL = ADMIN_BRAND_URL + '/create'
export const ADMIN_BRAND_MANAGEMENT_URL = '/admin/brand-management'
export const ADMIN_ASSIGN_BRAND_TO_USER_URL = '/admin/users/assign-brand'

// super admin
export const SUPER_ADMIN_DASHBOARD_URL= '/admin/super-admin'
export const SUPER_ADMIN_USER_MANAGEMENT_URL= SUPER_ADMIN_DASHBOARD_URL + '/user-management'
export const SUPER_ADMIN_EDIT_USER_URL= SUPER_ADMIN_DASHBOARD_URL + '/edit-user'
