import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import LineStyleIcon from '@mui/icons-material/LineStyle';
import {green} from "@mui/material/colors";
import Copyright from "../Copyright";
import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {isEmpty} from "../../utils/stringUtils";
import BrandAutoComplete from "../../components/admin/BrandAutoComplete";
import {assignBrandToSticker} from "../../api/admin";

const AssignBrandToSticker = () => {
    const [brandId, setBrandId] = useState<string>("")
    const [brandIdErr, setBrandIdErr] = useState<boolean>(false)

    const [startingVisibleId, setStartingVisibleId] = useState<number>(0)
    const [startingVisibleIdErr, setStartingVisibleIdErr] = useState<boolean>(false)
    const [startingVisibleIdErrText, setStartingVisibleIdErrText] = useState<string>('')

    const [endingVisibleId, setEndingVisibleId] = useState<number>(0)
    const [endingVisibleIdErr, setEndingVisibleIdErr] = useState<boolean>(false)
    const [endingVisibleIdErrText, setEndingVisibleIdErrText] = useState<string>('')

    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("")


    const axios = useAxiosPrivate()
    const sendNotification = useNotificationStore((state) => state.sendNotification)

    const handleSetStartingVisibleId = (val: string) => {
        const startingVId = Number(val)
        if (isNaN(startingVId)) {
            setStartingVisibleIdErr(true)
            setStartingVisibleIdErrText("Starting visible id must be a number!")
            return
        }

        setStartingVisibleId(startingVId)
    }

    const handleSetEndingVisibleId = (val: string) => {
        const endingVid = Number(val)
        if (isNaN(endingVid)) {
            setEndingVisibleIdErr(true)
            setEndingVisibleIdErrText("Ending visible id must be a number!")
            return
        }

        setEndingVisibleId(endingVid)
    }

    useEffect(() => {
        if (startingVisibleId <= 0) {
            setStartingVisibleIdErr(true)
            setStartingVisibleIdErrText("Starting visible id must be greater than zero!")
            return
        }

        if (endingVisibleId <= 0) {
            setEndingVisibleIdErr(true)
            setEndingVisibleIdErrText("Ending visible id must be greater than zero!")
            return
        }

        if (startingVisibleId > endingVisibleId) {
            setStartingVisibleIdErr(true);
            setStartingVisibleIdErrText("Starting visible id can not be greater than ending visible id!");

            setEndingVisibleIdErr(true);
            setEndingVisibleIdErrText("Ending visible id can not be smaller than starting visible id!");
            return
        }


        setStartingVisibleIdErr(false);
        setStartingVisibleIdErrText("");

        setEndingVisibleIdErr(false);
        setEndingVisibleIdErrText("");
    }, [brandId, startingVisibleId, endingVisibleId])


    useEffect(() => {
        if (brandIdErr || startingVisibleIdErr || endingVisibleIdErr) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [brandIdErr, startingVisibleIdErr, endingVisibleIdErr])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisableSubmit(true)

        if (isEmpty(brandId)) {
            setBrandIdErr(true);
        }

        if (startingVisibleId <= 0) {
            setStartingVisibleIdErr(true);
            setStartingVisibleIdErrText("Starting visible id can not be less than zero!");
        }

        if (startingVisibleId <= 0) {
            setEndingVisibleIdErr(true);
            setEndingVisibleIdErrText("Ending visible id can not be less than zero!");
        }

        if (startingVisibleId > endingVisibleId) {
            setStartingVisibleIdErr(true);
            setStartingVisibleIdErrText("Starting visible id can not be greater than ending visible id!");

            setEndingVisibleIdErr(true);
            setEndingVisibleIdErrText("Ending visible id can not be smaller than ending visible id!");
        }

        if (isEmpty(brandId) || startingVisibleId <= 0 || endingVisibleId <= 0 ||  startingVisibleId > endingVisibleId) {
            return;
        }

        setLoading(true)
        assignBrandToSticker(axios, {brand_id: brandId, starting_visible_id: startingVisibleId, ending_visible_id: endingVisibleId})
          .then(response => {
              if (response.result) {
                  setSuccess(true);
                  setSuccessMessage(response.message)
              } else {
                  setSuccess(false)
                  sendNotification('error', response.message)
              }
          })
        setLoading(false);
        setDisableSubmit(false)
    };

    const theme = useTheme();
    const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
    let sx;
    if (mobileView) {
        sx = {
            mt: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center'
        }
    } else {
        sx = {
            mt: 1,
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center'
        }
    }

    const requestForm = (
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={sx}
      >

          <BrandAutoComplete setBrandIdHandler={setBrandId} setBrandIdErrHandler={setBrandIdErr} />

          <TextField
            type="number"
            margin="normal"
            required
            fullWidth
            id="starting-visible-id"
            label="Starting Visible Id"
            name="starting-visible-id"
            autoComplete="off"
            autoFocus
            onChange={(e) => handleSetStartingVisibleId(e.target.value)}
            error={startingVisibleIdErr}
            helperText={startingVisibleIdErrText}
          />

          <TextField
            type="number"
            margin="normal"
            required
            fullWidth
            id="ending-visible-id"
            label="Ending Visible Id"
            name="ending-visible-id"
            autoComplete="off"
            autoFocus
            onChange={(e) => handleSetEndingVisibleId(e.target.value)}
            error={endingVisibleIdErr}
            helperText={endingVisibleIdErrText}
          />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={disableSubmit}
          >
              {
                  loading ? (
                    <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
                  ) : (
                    <span>Submit</span>
                  )
              }
          </Button>
      </Box>
    )

    return (
      <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
              <CssBaseline />
              <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
              >
                  <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                      <LineStyleIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                      Assign Brand To Sticker
                  </Typography>

                  {
                      success ? (
                        <Typography variant='h4' color={green.A700}>{successMessage}</Typography>
                      ) : (
                        requestForm
                      )
                  }
              </Box>
              <Copyright />
          </Container>
      </ThemeProvider>
    );
}

export default AssignBrandToSticker