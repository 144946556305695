import {Axios} from "axios";
import {Response, StatisticsResponse} from "./responses"

export interface ConnectPrdocutInfoRequest {
  product_name: string
  message:      string
}

export interface AddProductRequest {
  product_name: string
  product_type: string
  model_name:   string
  author_name:  string
  product_image: File | null
}

export const addProduct = async (axios: Axios,
  request: AddProductRequest): Promise<Response> => {
  const abortController = new AbortController();

  const formData = new FormData();
  if(request.product_image) {
    formData.append("product_image", request.product_image);
  }

  formData.append("product_name", request.product_name);
  formData.append("product_type", request.product_type);
  formData.append("model_name", request.model_name);
  formData.append("author_name", request.author_name);


  return axios.post<Response>(`/jbrand/add-product`, formData, {
    signal: abortController.signal,
  }).then(response => {
    return response.data
  }).catch(err => {
    if (err?.response?.status === 400) {
      return err?.response?.data
    }
    return {
      result: false, message: 'Something went wrong! Please Try Again!'
    }
  }).finally(() => {
    abortController.abort();
  })
}

export const connectProductInfoWithSticker = async (axios: Axios,
                                                    stickerId: string,
                                                    request: ConnectPrdocutInfoRequest): Promise<Response> => {
  const abortController = new AbortController();
  return axios.put<Response>(`/jbrand/stickers/${stickerId}`, JSON.stringify(request), {
    signal: abortController.signal
  }).then(response => {
    return response.data
  }).catch(err => {
    if (err?.response?.status === 400) {
      return err?.response?.data
    }
    return {result: false, message: 'Something went wrong! Please Try Again!'}
  }).finally(() => {
    abortController.abort();
  })
}

export const connectProductInfoWithMultipleSticker = async (axios: Axios,
                                                    startingStickerId: number, endingStickerId: number,
                                                    request: ConnectPrdocutInfoRequest): Promise<Response> => {
  const abortController = new AbortController();
  return axios.put<Response>(`jbrand/stickers/batch-processing/connect-products?startingVisibleId=${startingStickerId}&endingVisibleId=${endingStickerId}`,
    JSON.stringify(request), {signal: abortController.signal}).then(response => {
    return response.data
  }).catch(err => {
    if (err?.response?.status === 400) {
      return err?.response?.data
    }
    return {result: false, message: 'Something went wrong! Please Try Again!'}
  }).finally(() => {
    abortController.abort();
  })
}

export const updateConnectedProductInfoWithSticker = async (axios: Axios,
                                                    stickerId: string,
                                                    request: ConnectPrdocutInfoRequest): Promise<Response> => {
  const abortController = new AbortController();
  return axios.patch<Response>(`/jbrand/stickers/${stickerId}`, JSON.stringify(request), {
    signal: abortController.signal
  }).then(response => {
    return response.data
  }).catch(err => {
    if (err?.response?.status === 400) {
      return err?.response?.data
    }
    return {result: false, message: 'Something went wrong! Please Try Again!'}
  }).finally(() => {
    abortController.abort();
  })
}

export const updateConnectedProductInfoWithMultipleSticker = async (axios: Axios,
                                                                    startingStickerId: number, endingStickerId: number,
                                                                    request: ConnectPrdocutInfoRequest): Promise<Response> => {
  const abortController = new AbortController();
  return axios.patch<Response>(`jbrand/stickers/batch-processing/connect-products?startingVisibleId=${startingStickerId}&endingVisibleId=${endingStickerId}`,
    JSON.stringify(request), {signal: abortController.signal}).then(response => {
    return response.data
  }).catch(err => {
    if (err?.response?.status === 400) {
      return err?.response?.data
    }
    return {result: false, message: 'Something went wrong! Please Try Again!'}
  }).finally(() => {
    abortController.abort();
  })
}

export const getDashboardStatistics = async (axios: Axios): Promise<StatisticsResponse> => {
  const abortController = new AbortController();
  return axios.get<StatisticsResponse>('/jbrand/stickers/brand-dashboard-statistics', {
    signal: abortController.signal
  }).then(response => {
    return response.data
  }).catch(err => {
    if (err?.response?.status === 400) {
      return err?.response?.data
    }
    return {result: false, message: 'Something went wrong! Please Try Again!'}
  }).finally(() => {
    abortController.abort();
  })
}
