import {Axios} from "axios";
import {GetAllUserFilter, UpdateUserRoleRequest} from "./requests";
import {
  Response,
  GetAllUserResponse,
  GetOneUserResponse,
  RoleResponse
} from "./responses";
import {generateQueryParamFromFilter} from "./axios";

export const getAllUser = async (axios: Axios, filter: GetAllUserFilter): Promise<GetAllUserResponse> => {
  const query = generateQueryParamFromFilter(filter)
  const abortController = new AbortController();
  return axios.get<GetAllUserResponse>(`/jsuper-admin/users?${query}`,
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
  }).finally(() => {
    abortController.abort()
  })
}

export const getUserById = async (axios: Axios, id: string): Promise<GetOneUserResponse> => {
  const abortController = new AbortController();
  return axios.get<GetOneUserResponse>(`/jsuper-admin/users/${id}`,
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}

export const getAllRoles = async (axios: Axios): Promise<RoleResponse> => {
  const abortController = new AbortController();
  return axios.get<RoleResponse>(`/jsuper-admin/roles`,
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}

export const updateUserRole = async (axios: Axios, request: UpdateUserRoleRequest): Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>('/jsuper-admin/users/update-role', JSON.stringify(request),
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}
