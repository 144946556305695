import axios from 'axios'
import {BACKEND_BASE_URL} from '../../config'

export default axios.create({
    baseURL: BACKEND_BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BACKEND_BASE_URL,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
})

export const generateQueryParamFromFilter = (filter: Object): string => {
    return Object.entries(filter).map(e => {
        return `${e[0]}=${e[1]}`
    }).join("&")
}