import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {getRequiredMessage} from "../../constants/errormessages";
import {createNewBrand} from "../../api/admin";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import {green} from "@mui/material/colors";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Copyright from "../Copyright";
import {isEmpty} from "../../utils/stringUtils";

const CreateBrand = () => {
  const [brandName, setBrandName] = useState<string>("")
  const [brandNameErr, setBrandNameErr] = useState<boolean>(false)
  const [brandNameErrText, setBrandNameErrText] = useState<string>("")

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const axios = useAxiosPrivate()
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  const handleSetBrandName = (val : string) => {
    setBrandName(val)
    if (isEmpty(val)) {
      setBrandNameErr(true)
      setBrandNameErrText(getRequiredMessage("Brand Name"))
      return
    }
    if (val.length < 4) {
      setBrandNameErr(true)
      setBrandNameErrText("Brand Name must be greater than 3 character!")
      return
    }

    setBrandNameErr(false)
    setBrandNameErrText("")
  }

  useEffect(() => {
    if (brandNameErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [brandNameErr])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableSubmit(true)

    if (isEmpty(brandName)) {
      setBrandNameErr(true);
      setBrandNameErrText(getRequiredMessage("Brand Name"));
    }

    if (brandNameErr || isEmpty(brandName)) {
      return;
    }

    setLoading(true)
    createNewBrand(axios, {name: brandName}).then(response => {
      if (response.result) {
        setSuccess(true)
      } else {
        setSuccess(false)
        sendNotification('error', response.message);
      }
    })

    setLoading(false);
    setDisableSubmit(false)
  };

  const theme = useTheme();
  const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
  let sx;
  if (mobileView) {
    sx = {
      mt: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  } else {
    sx = {
      mt: 1,
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

  const requestForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={sx}
    >
      <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="brand-name"
        label="Brand Name"
        name="name"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetBrandName(e.target.value)}
        error={brandNameErr}
        helperText={brandNameErrText}
      />
      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disableSubmit}
      >
        {
          loading ? (
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
          ) : (
            <span>Submit</span>
          )
        }
      </Button>
    </Box>

  )

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <NoteAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create new Brand
          </Typography>

          {
            success ? (
              <Typography variant='h4' color={green.A700}>Brand Creation Successful!</Typography>
            ) : (
              requestForm
            )
          }
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default CreateBrand