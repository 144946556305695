import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {getStickerStatisticsShort} from "../../api/admin";
import {Statistics} from "../../api/responses";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {styled} from "@mui/material/styles";
import AssessmentIcon from '@mui/icons-material/Assessment';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {StickerDetailsReportFilter} from "../../api/requests";
import AdminDashboardDetails from "./AdminDashboardDetails";
import AdminDashboardDetailsFilter from "../../components/admin/AdminDashboardDetailsFilter";
import Avatar from "@mui/material/Avatar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#a7a7a761',
        color: 'black',
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AdminDashboard = () => {
    let axios = useAxiosPrivate();
    const [statistics, setStatistics] = useState<Statistics[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [detailsReportFilter, setDetailsReportFilter] = useState<StickerDetailsReportFilter>({})
    const [loadDetailsReport, setLoadDetailsReport] = useState<boolean>(false)
    const [refetchDetailsReport, setRefetchDetailsReport] = useState<boolean>(false)

    const handleDetailsReportFilterFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoadDetailsReport(true);
        setRefetchDetailsReport(prevState => !prevState)
    };

    useEffect(() => {
        setLoading(true);
        getStickerStatisticsShort(axios).then(res => {
            if (res.result) {
                setStatistics(res.data);
                setSuccess(true);
            } else {
                setSuccess(false);
            }
            setLoading(false);
        });
    }, [axios]);

    const getShortReportTable = () => {
        return (
            <TableContainer sx={{width: 1000}} component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {
                                statistics.map(value => (
                                    <StyledTableCell key={`short-details-status-${value.status}`} align="center">{value.status}</StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {statistics.map(value => (
                                <StyledTableCell key={`short-details-status-total-${value.total}`} align="center">{value.total}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <>
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                    <AssessmentIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Short Report
                </Typography>
            </Container>
        <Container component="main" maxWidth="md" sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 5
        }}>
            {!success &&
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                >Error occurred during fetching short report data</Typography>
            }

            <div>
                {success && getShortReportTable()}
            </div>
        </Container>

        <Container>
            <div>
                <AdminDashboardDetailsFilter
                    filter={setDetailsReportFilter}
                    handleFormSubmit={handleDetailsReportFilterFormSubmit}/>
            </div>

            <div>
                {loadDetailsReport && <AdminDashboardDetails filter={detailsReportFilter} refetchData={refetchDetailsReport}/>}
            </div>
        </Container>
        </>
    );
}

export default AdminDashboard;