import {Brand} from "../../model";
import React, {Dispatch, SetStateAction} from "react";
import {useNavigate} from "react-router-dom";
import NoDataFound from "../NoDataFound";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {Button, TablePagination} from "@mui/material";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";

interface BrandListProps {
  brands: Brand[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  perPage: number
  setPerPage: Dispatch<SetStateAction<number>>
  totalBrands: number
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#a7a7a761',
    color: 'black',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const BrandList = ({brands, page, setPage, perPage, setPerPage, totalBrands}: BrandListProps) => {
  const navigate = useNavigate()
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!brands) {
    return <NoDataFound />
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SL</StyledTableCell>
              <StyledTableCell align="right">Id</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">CreatedAt</StyledTableCell>
              <StyledTableCell align="right">UpdatedAt</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((brand, index) => (
              <StyledTableRow key={brand.id}>
                <StyledTableCell align="right">{(page * perPage) + index + 1}</StyledTableCell>
                <StyledTableCell align="right">{brand.id}</StyledTableCell>
                <StyledTableCell align="right">{brand.name}</StyledTableCell>
                <StyledTableCell align="right">{brand.created_at}</StyledTableCell>
                <StyledTableCell align="right">{brand.updated_at}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button onClick={() => navigate(`/admin/edit-brand/${brand.id}`)}>Edit</Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={totalBrands}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default BrandList;