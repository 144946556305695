import {Box, Container, List, ListItem, ListItemText, Paper} from "@mui/material";

interface OperationProp {
  title: string
  onClick: () => void
}

interface OperationListProps {
  operationList: OperationProp[]
}

const OperationList = ({operationList}: OperationListProps) => {
  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <List sx={{
          width: '100%',
          bgcolord: 'background.paper',
          boxShadow: '0px 0px 3px 1px gray',
          borderRadius: '10px'
        }}
        >
          {
            operationList.map((op, index) => {
              let divider = true;
              if (index === operationList.length -1) {
                divider = false;
              }
              return (
              <ListItem key={op.title} button divider={divider} onClick={op.onClick}>
                <ListItemText primary={op.title} />
              </ListItem>
            )})
          }
        </List>
      </Box>
      <Paper elevation={3} />
    </Container>
  )
}

export default OperationList;