import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import PrintIcon from '@mui/icons-material/Print';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../../stores/useNotificationStore";
import {green} from "@mui/material/colors";
import Copyright from "../../Copyright";
import {isEmpty} from "../../../utils/stringUtils";
import {getRequiredMessage} from "../../../constants/errormessages";
import {updateConnectedProductInfoWithMultipleSticker} from "../../../api/brand";

const UpdateMultipleProduct = () => {
  const [startingVisibleId, setStartingVisibleId] = useState<number>(0)
  const [startingVisibleIdErr, setStartingVisibleIdErr] = useState<boolean>(false)
  const [startingVisibleIdErrText, setStartingVisibleIdErrText] = useState<string>('')

  const [endingVisibleId, setEndingVisibleId] = useState<number>(0)
  const [endingVisibleIdErr, setEndingVisibleIdErr] = useState<boolean>(false)
  const [endingVisibleIdErrText, setEndingVisibleIdErrText] = useState<string>('')

  const [productName, setProductName] = useState<string>("")
  const [productNameErr, setProductNameErr] = useState<boolean>(false)
  const [productNameErrText, setProductNameErrText] = useState<string>("")

  const [message, setMessage] = useState<string>("")
  const [messageErr, setMessageErr] = useState<boolean>(false)
  const [messageErrText, setMessageErrText] = useState<string>("")

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");


  const axios = useAxiosPrivate()
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  const handleSetStartingVisibleId = (val: string) => {
    const startingVId = Number(val)
    if (isNaN(startingVId)) {
      setStartingVisibleIdErr(true)
      setStartingVisibleIdErrText("Starting visible id must be a number!")
      return
    }

    setStartingVisibleId(startingVId)
  }

  const handleSetEndingVisibleId = (val: string) => {
    const endingVid = Number(val)
    if (isNaN(endingVid)) {
      setEndingVisibleIdErr(true)
      setEndingVisibleIdErrText("Ending visible id must be a number!")
      return
    }

    setEndingVisibleId(endingVid)
  }

  const handleSetProductName = (val : string) => {
    setProductName(val)
    if (isEmpty(val)) {
      setProductNameErr(true)
      setProductNameErrText(getRequiredMessage("Product Name"))
      return
    }
    if (val.length < 4) {
      setProductNameErr(true)
      setProductNameErrText("Product Name must be greater than 3 character!")
      return
    }

    setProductNameErr(false)
    setProductNameErrText("")
  }

  const handleSetMessage = (val : string) => {
    setMessage(val)
    if (isEmpty(val)) {
      setMessageErr(true)
      setMessageErrText(getRequiredMessage("Message"))
      return
    }
    
    if (val.length < 4) {
      setMessageErr(true)
      setMessageErrText("Message must be greater than 3 character!")
      return
    }

    setMessageErr(false)
    setMessageErrText("")
  }

  useEffect(() => {
    if (startingVisibleId <= 0) {
      setStartingVisibleIdErr(true)
      setStartingVisibleIdErrText("Starting visible id must be greater than zero!")
      return
    }

    if (endingVisibleId <= 0) {
      setEndingVisibleIdErr(true)
      setEndingVisibleIdErrText("Ending visible id must be greater than zero!")
      return
    }

    if (startingVisibleId > endingVisibleId) {
      setStartingVisibleIdErr(true);
      setStartingVisibleIdErrText("Starting visible id can not be greater than ending visible id!");

      setEndingVisibleIdErr(true);
      setEndingVisibleIdErrText("Ending visible id can not be smaller than starting visible id!");
      return
    }


    setStartingVisibleIdErr(false);
    setStartingVisibleIdErrText("");

    setEndingVisibleIdErr(false);
    setEndingVisibleIdErrText("");
  }, [startingVisibleId, endingVisibleId])


  useEffect(() => {
    if (startingVisibleIdErr || endingVisibleIdErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [startingVisibleIdErr, endingVisibleIdErr])


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableSubmit(true)

    if (startingVisibleId <= 0) {
      setStartingVisibleIdErr(true);
      setStartingVisibleIdErrText("Starting visible id can not be less than zero!");
    }

    if (startingVisibleId <= 0) {
      setEndingVisibleIdErr(true);
      setEndingVisibleIdErrText("Ending visible id can not be less than zero!");
    }

    if (startingVisibleId > endingVisibleId) {
      setStartingVisibleIdErr(true);
      setStartingVisibleIdErrText("Starting visible id can not be greater than ending visible id!");

      setEndingVisibleIdErr(true);
      setEndingVisibleIdErrText("Ending visible id can not be smaller than ending visible id!");
    }

    if (startingVisibleId <= 0 || endingVisibleId <= 0 ||  startingVisibleId > endingVisibleId) {
      return;
    }

    if (productNameErr || isEmpty(productName)) {
      return;
    }

    setLoading(true)
    updateConnectedProductInfoWithMultipleSticker(axios, startingVisibleId, endingVisibleId, {product_name: productName, message: message})
      .then(response => {
        if (response.result) {
          setSuccess(true);
          setResponseMessage(response.message);
        } else {
          setSuccess(false)
          setResponseMessage("");
          sendNotification('error', response.message)
        }
      })
    setLoading(false);
    setDisableSubmit(false)
  };

  const theme = useTheme();
  const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
  let sx;
  if (mobileView) {
    sx = {
      mt: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  } else {
    sx = {
      mt: 1,
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

  const requestForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={sx}
    >

      <TextField
        type="number"
        margin="normal"
        required
        fullWidth
        id="starting-visible-id"
        label="Starting Visible Id"
        name="starting-visible-id"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetStartingVisibleId(e.target.value)}
        error={startingVisibleIdErr}
        helperText={startingVisibleIdErrText}
      />

      <TextField
        type="number"
        margin="normal"
        required
        fullWidth
        id="ending-visible-id"
        label="Ending Visible Id"
        name="ending-visible-id"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetEndingVisibleId(e.target.value)}
        error={endingVisibleIdErr}
        helperText={endingVisibleIdErrText}
      />

      <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="product-name"
        label="Product Name"
        name="product-name"
        autoComplete="off"
        onChange={(e) => handleSetProductName(e.target.value)}
        error={productNameErr}
        helperText={productNameErrText}
      />

      <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="message"
        label="Message"
        name="message"
        multiline
        maxRows={4}
        autoComplete="off"
        onChange={(e) => handleSetMessage(e.target.value)}
        error={messageErr}
        helperText={messageErrText}
      />

      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disableSubmit}
      >
        {
          loading ? (
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
          ) : (
            <span>Submit</span>
          )
        }
      </Button>
    </Box>
  )

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <PrintIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Update Product Info With Multiple Sticker
          </Typography>

          {
            success ? (
              <Typography variant='h4' color={green.A700}>{responseMessage}</Typography>
            ) : (
              requestForm
            )
          }
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default UpdateMultipleProduct;