import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useNotificationStore } from "../stores/useNotificationStore";

function Notification() {
  const { notificationType, notificationContent, resetNotification } =
    useNotificationStore((state) => ({
      notificationType: state.type,
      notificationContent: state.content,
      resetNotification: state.resetNotification,
    }));

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    resetNotification();
  };

  return (
    <Snackbar
      open={notificationContent !== ""}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      autoHideDuration={5000}
      sx={{ margin: "55px 20px" }}
    >
      <Alert severity={notificationType} sx={{ width: "100%" }}>
        {notificationContent}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
