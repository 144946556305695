import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useNotificationStore} from "../../../stores/useNotificationStore";
import {isEmpty} from "../../../utils/stringUtils";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Copyright from "../../Copyright";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  useTheme
} from "@mui/material";
import {getAllRoles, getUserById, updateUserRole} from "../../../api/superadmin";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {Role} from "../../../model";

interface UserShortInfo {
  id: string
  name: string
  email: string
  phone: string
  brandId: string
  roles: string[]
}

interface RoleCheckBoxData extends Role {
  isChecked?: boolean
}

const EditUser = () => {
  const axios = useAxiosPrivate()
  const params = useParams()
  const theme = useTheme()

  const [allRoles, setAllRoles] = useState<RoleCheckBoxData[]>([])
  const [allRolesLoaded, setAllRolesLoaded] = useState<boolean>(false)

  const [user, setUser] = useState<UserShortInfo>({
    id: '',
    name: '',
    email: '',
    phone: '',
    brandId: '',
    roles: []
  })

  const [userInfoLoaded, setUserInfoLoaded] = useState<boolean>(false)

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const sendNotification = useNotificationStore((state) => state.sendNotification);

  const fetchData = async () => {
    const userId = String(params.id)
    if (isEmpty(userId)) {
      return Promise.reject('User id can not be blank!');
    }

    const roleResponse = await getAllRoles(axios);
    if (!roleResponse.result) {
      return Promise.reject('Unable to load roles! Please try again!');
    }
    const rolesData = roleResponse.data;

    const userResponse = await getUserById(axios, userId);
    if (!userResponse.result) {
      return Promise.reject('Error during fetching user information! Please try again!');
    }
    const userData = userResponse.data

    setAllRoles(rolesData.map(r => userData.roles.includes(r.name) ? {...r, isChecked: true}: r))
    setAllRolesLoaded(true)

    setUser({
      id: userData.id,
      name: userData.name,
      email: userData.email,
      phone: userData.phone,
      brandId: userData.brand_id,
      roles: userData.roles
    })
    setUserInfoLoaded(true)
    return Promise.resolve();
  }

  useEffect(()=> {
    fetchData().catch(err => {
      sendNotification('error', err)
    })
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisableSubmit(true)
    const activeRoleIds = allRoles.filter(r => r.isChecked).map(r => r.id);

    updateUserRole(axios, {user_id: user.id, roles: activeRoleIds})
      .then(response => {
        if (response.result) {
          sendNotification('success', response.message)
        } else {
          sendNotification('error', response.message)
        }
      })
    setDisableSubmit(false)
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllRoles(allRoles.map(r => r.name === event.target.name ? {...r, isChecked: !r.isChecked} : r));
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!allRolesLoaded && !userInfoLoaded}
      >
        <Typography >
          Loading
        </Typography>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <DriveFileRenameOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Edit User
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={user.name}
                  label="Name"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={user.phone}
                  label="Phone"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={user.email}
                  label="Email Address"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  value={user.brandId}
                  label="Brand Id"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ mt: 1}} component="fieldset" variant="standard">
                  <FormLabel component="legend">Assign Role</FormLabel>
                  <FormGroup>
                    <Box component="div" sx={{
                      display: 'flex'
                    }}>
                      { allRolesLoaded &&
                        allRoles.map(role => {
                          let disabled = false;
                          if (role.name === 'ROLE_SUPER_ADMIN') {
                            disabled = true;
                          }
                          return (
                          <Box key={role.id}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={role?.isChecked || false} onChange={handleChange} name={role.name} disabled={disabled}/>
                            }
                            label={role.name}
                          />
                          </Box>
                        )})
                      }
                    </Box>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disableSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default EditUser;