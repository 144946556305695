import {useNavigate} from "react-router-dom";
import OperationList from "../../../components/OperationList";
import {
  BRAND_PRODUCT_CONNECT_MULTIPLE_STICKER_URL,
  BRAND_PRODUCT_CONNECT_SINGLE_STICKER_URL, BRAND_PRODUCT_UPDATE_MULTIPLE_STICKER_URL,
  BRAND_PRODUCT_UPDATE_SINGLE_STICKER_URL
} from "../../../constants/urls";

const ConnectProductHome= () => {
  const navigate = useNavigate();
  const operationList = [
    {
      title: 'Connect With Single Sticker',
      onClick: () => navigate(BRAND_PRODUCT_CONNECT_SINGLE_STICKER_URL)
    },
    {
      title: 'Connect With Multiple Sticker',
      onClick: () => navigate(BRAND_PRODUCT_CONNECT_MULTIPLE_STICKER_URL)
    },
    {
      title: 'Update Product Info For Single Sticker',
      onClick: () => navigate(BRAND_PRODUCT_UPDATE_SINGLE_STICKER_URL)
    },
    {
      title: 'Update Product Info For Multiple Sticker',
      onClick: () => navigate(BRAND_PRODUCT_UPDATE_MULTIPLE_STICKER_URL)
    }
  ]

  return (
    <OperationList operationList={operationList} />
  )
}

export default ConnectProductHome;
