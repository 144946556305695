import { Navigate } from "react-router-dom";
import useSignOut from "../../hooks/useSignOut";

const SignOut = () => {
  const signout = useSignOut()
  signout()

  return <Navigate to="/signin" replace />;
};

export default SignOut;
