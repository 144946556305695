import axios from '../api/axios'
import {useAuthenticationStore} from "../stores/useAuthenticationStore";
import {LoginResponse} from "../api/responses";

const useRefreshToken = () => {
  const {setAuthentication, removeAuthentication} = useAuthenticationStore(
    (state) => ({setAuthentication: state.setAuthentication, removeAuthentication: state.removeAuthentication}))

  return async () => {
    try {
      const response = await axios.post<LoginResponse>('/refresh', {}, {withCredentials: true});
      const newJwtToken = response?.data?.token
      setAuthentication(newJwtToken)
      return newJwtToken
    } catch (err) {
      removeAuthentication()
    }
  }
}
export default useRefreshToken