import HiddenQrCode from "./HiddenQrCode";
import VisibleBarCode from "./VisibleBarCode";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface StickerProps {
  visibleBarcodeValue: string;
  hiddenQrCodeValue: string;
}

const StyledDiv = styled("div")({
  display: "block",
});

const Sticker = ({ visibleBarcodeValue, hiddenQrCodeValue }: StickerProps) => {
  return (
    <StyledDiv>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <HiddenQrCode value={hiddenQrCodeValue} />
      </div>
      <div
        style={{
          textAlign: "center",
          width: "0.8in",
        }}
      >
        <VisibleBarCode value={visibleBarcodeValue} />
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "16px",
        }}
      >
        <Typography margin="0" variant="h5" textAlign="center">
          যাচাই
        </Typography>
      </div>
    </StyledDiv>
  );
};

export default Sticker;
