import {
  Response,
  AutocompleteResponseData,
  GetAllBrandResponse,
  GetOneBrandResponse, StickerForPrintResponse, StatisticsResponse, DetailsStatisticsResponse
} from "./responses";
import {Axios} from "axios";
import {
  AssignBrandToStickerRequest,
  AssignBrandToUserRequest,
  AutocompleteRequest, BrandUpdateRequest,
  CreateNewBrandRequest, GetAllBrandFilter, StickerDetailsReportFilter, UpdateStickerStatusRequest
} from "./requests";
import {generateQueryParamFromFilter} from "./axios";
import {StickerStatus} from "../constants/constants";

export const generateSticker = async (axios: Axios, quantity: number) : Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>(`/jadmin/stickers/generate?quantity=${quantity}`, {}, {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err.response.status === 400) {
        return err.result?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const createNewBrand = async (axios: Axios, request: CreateNewBrandRequest) : Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>('/jadmin/brands', JSON.stringify(request), {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const brandAutoComplete = async (axios: Axios, abortController: AbortController, request: AutocompleteRequest) : Promise<AutocompleteResponseData> => {
  return axios.post<Response>('/jadmin/brands/autocomplete', JSON.stringify(request), {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const assignBrandToUser = async (axios: Axios, request: AssignBrandToUserRequest) : Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>('/jadmin/brands/assign-user', JSON.stringify(request), {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const assignBrandToSticker = async (axios: Axios, request: AssignBrandToStickerRequest) : Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>('/jadmin/stickers/assign-brand', JSON.stringify(request), {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      console.log(err?.response?.data)
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const getAllBrand = async (axios: Axios, abortController: AbortController, filter: GetAllBrandFilter): Promise<GetAllBrandResponse> => {
  const query = generateQueryParamFromFilter(filter)
  return axios.get<GetAllBrandResponse>(`/jadmin/brands?${query}`,
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err.toJSON().code === 'ERR_CANCELED') {
        return {result: false, message: 'Request canceled!'}
      }

      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}

export const getBrandById = async (axios: Axios, id: string): Promise<GetOneBrandResponse> => {
  const abortController = new AbortController();
  return axios.get<GetOneBrandResponse>(`/jadmin/brands/${id}`,
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}

export const updateBrandInfo = async (axios: Axios, request: BrandUpdateRequest): Promise<Response> => {
  const abortController = new AbortController();
  return axios.put<Response>('/jadmin/brands', JSON.stringify(request),
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}

export const getStickersForPrint = async (axios: Axios, startingId: number, endingId:number): Promise<StickerForPrintResponse> => {
  const abortController = new AbortController();
  return axios.get<StickerForPrintResponse>(`/jadmin/stickers?starting_visible_id=${startingId}&ending_visible_id=${endingId}`,
    {signal: abortController.signal})
    .then(response => {
      return response?.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort()
    })
}

export const updateStickerStatus = async (axios: Axios, request: UpdateStickerStatusRequest) : Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>('/jadmin/stickers/update-status', JSON.stringify(request), {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      console.log(err?.response?.data)
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const updateStickerStatusToLost = async (axios: Axios, request: UpdateStickerStatusRequest) : Promise<Response> => {
  const abortController = new AbortController();
  return axios.post<Response>('/jadmin/stickers/update-status-to-lost', JSON.stringify(request), {
    signal: abortController.signal
  })
    .then((response)=> {
      return response.data
    }).catch(err => {
      if (err?.response?.status === 400) {
        return err?.response?.data
      }
      return {result: false, message: 'Something went wrong! Please Try Again!'}
    }).finally(() => {
      abortController.abort();
    })
}

export const getStickerStatisticsShort = async (axios: Axios) : Promise<StatisticsResponse> => {
  const abortController = new AbortController();
  return axios.get<Response>('/jadmin/stickers/query/analysis-report-short', {
    signal: abortController.signal
  })
      .then((response)=> {
        return response.data
      }).catch(err => {
        if (err?.response?.status === 400) {
          return err?.response?.data
        }
        console.log(err?.response?.data)
        return {result: false, message: 'Something went wrong! Please Try Again!'}
      }).finally(() => {
        abortController.abort();
      })
}

export const getStickerStatisticsDetails = async (axios: Axios, filter: StickerDetailsReportFilter) : Promise<DetailsStatisticsResponse> => {
  const abortController = new AbortController();
  return axios.post<DetailsStatisticsResponse>('/jadmin/stickers/query/analysis-report-details', JSON.stringify(filter), {
    signal: abortController.signal
  })
      .then((response)=> {
        return response.data;
      }).catch(err => {
        if (err?.response?.status === 400) {
          return err?.response?.data
        }
        console.log(err?.response?.data)
        return {result: false, message: 'Something went wrong! Please Try Again!'}
      }).finally(() => {
        abortController.abort();
      })
}
