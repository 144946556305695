import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {useNotificationStore} from "../../../stores/useNotificationStore";
import {isEmpty} from "../../../utils/stringUtils";
import {getRequiredMessage} from "../../../constants/errormessages";
import {Box, Button, CircularProgress, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {green} from "@mui/material/colors";
import Copyright from "../../Copyright";
import {connectProductInfoWithSticker} from "../../../api/brand";

const ConnectProduct = () => {
  const [stickerId, setStickerId] = useState<string>("")
  const [stickerIdErr, setStickerIdErr] = useState<boolean>(false)
  const [stickerIdErrText, setStickerIdErrText] = useState<string>("")

  const [productName, setProductName] = useState<string>("")
  const [productNameErr, setProductNameErr] = useState<boolean>(false)
  const [productNameErrText, setProductNameErrText] = useState<string>("")

  const [message, setMessage] = useState<string>("")
  const [messageErr, setMessageErr] = useState<boolean>(false)
  const [messageErrText, setMessageErrText] = useState<string>("")

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const axios = useAxiosPrivate()
  const sendNotification = useNotificationStore((state) => state.sendNotification)

  useEffect(() => {
    if (productNameErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [productNameErr])

  const handleSetStickerId = (val: string) => {
    setStickerId(val)
    if (isEmpty(val)) {
      setStickerIdErr(true)
      setStickerIdErrText(getRequiredMessage("Sticker Id"))
      return
    }

    setStickerIdErr(false)
    setStickerIdErrText("")
  }

  const handleSetProductName = (val : string) => {
    setProductName(val)
    if (isEmpty(val)) {
      setProductNameErr(true)
      setProductNameErrText(getRequiredMessage("Product Name"))
      return
    }
    if (val.length < 4) {
      setProductNameErr(true)
      setProductNameErrText("Product Name must be greater than 3 character!")
      return
    }

    setProductNameErr(false)
    setProductNameErrText("")
  }

  const handleSetMessage = (val : string) => {
    setMessage(val)
    if (isEmpty(val)) {
      setMessageErr(true)
      setMessageErrText(getRequiredMessage("Message"))
      return
    }
    
    if (val.length < 4) {
      setMessageErr(true)
      setMessageErrText("Message must be greater than 3 character!")
      return
    }

    setMessageErr(false)
    setMessageErrText("")
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableSubmit(true)

    if (isEmpty(stickerId)) {
      setStickerIdErr(true);
      setStickerIdErrText(getRequiredMessage("Sticker Id"));
    }

    if (isEmpty(productName)) {
      setProductNameErr(true);
      setProductNameErrText(getRequiredMessage("Product Name"));
    }

    if (isEmpty(message)) {
      setMessageErr(true);
      setMessageErrText(getRequiredMessage("Message"));
    }

    if (productNameErr || isEmpty(productName)) {
      return;
    }

    setLoading(true)
    connectProductInfoWithSticker(axios, stickerId, {product_name: productName, message: message})
      .then(response => {
        if (response.result) {
          setSuccess(true)
        } else {
          setSuccess(false)
          sendNotification('error', response.message);
        }
      })

    setLoading(false);
    setDisableSubmit(false)
  };

  const theme = useTheme();
  const mobileView = !useMediaQuery(theme.breakpoints.up('sm'));
  let sx;
  if (mobileView) {
    sx = {
      mt: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  } else {
    sx = {
      mt: 1,
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center'
    }
  }

  const requestForm = (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={sx}
    >
      <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="sticker-id"
        label="Sticker Id"
        name="sticker-id"
        autoComplete="off"
        autoFocus
        onChange={(e) => handleSetStickerId(e.target.value)}
        error={stickerIdErr}
        helperText={stickerIdErrText}
      />

      <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="product-name"
        label="Product Name"
        name="product-name"
        autoComplete="off"
        onChange={(e) => handleSetProductName(e.target.value)}
        error={productNameErr}
        helperText={productNameErrText}
      />

    <TextField
        type="text"
        margin="normal"
        required
        fullWidth
        id="message"
        label="Message"
        name="message"
        multiline
        maxRows={4}
        autoComplete="off"
        onChange={(e) => handleSetMessage(e.target.value)}
        error={messageErr}
        helperText={messageErrText}
      />

      <Button
        fullWidth
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disableSubmit}
      >
        {
          loading ? (
            <span style={{display: 'flex', flexDirection: 'row'}}>
              <CircularProgress color="inherit" sx={{margin: '0 15px'}} size={25}/>
              Please Wait
            </span>
          ) : (
            <span>Submit</span>
          )
        }
      </Button>
    </Box>

  )

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <NoteAddIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connect Product Info With Sticker
          </Typography>

          {
            success ? (
              <Typography variant='h4' color={green.A700}>Product info connected!</Typography>
            ) : (
              requestForm
            )
          }
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default ConnectProduct;