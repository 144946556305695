import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, TextField, Typography } from "@mui/material";
import Copyright from "../Copyright";
import { validateEmail } from "../../utils/validators";
import { isEmpty } from "../../utils/stringUtils";
import { getCanNotBeEmptyMessage, getRequiredMessage } from "../../constants/errormessages";
import { useNotificationStore } from "../../stores/useNotificationStore";
import { useAuthenticationStore } from "../../stores/useAuthenticationStore";
import { useLocation, useNavigate, Link} from "react-router-dom";
import { login } from "../../api/authentication"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const theme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxiosPrivate();

  const from: string =
    typeof location?.state === "string" ? location?.state : "/";

  const [email, setEmail] = useState<string>("");
  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [emailErrText, setEmailErrText] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<boolean>(false);
  const [passwordErrText, setPasswordErrText] = useState<string>("");

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const sendNotification = useNotificationStore(
    (state) => state.sendNotification
  );

  const setAuthentication = useAuthenticationStore(
    (state) => state.setAuthentication
  );

  const handleSetEmail = (val: string) => {
    setEmail(val);
    if (!validateEmail(val)) {
      setEmailErr(true);
      setEmailErrText("Invalid email format!");
      return;
    } else {
      setEmailErr(false);
      setEmailErrText("");
      return;
    }
  };

  const handleSetPassword = (val: string) => {
    setPassword(val);
    if (val === "") {
      setPasswordErr(true);
      setPasswordErrText(getCanNotBeEmptyMessage("Password"));
      return;
    }
    setPasswordErr(false);
    setPasswordErrText("");
  };

  useEffect(() => {
    if (emailErr || passwordErr) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [emailErr, passwordErr]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setDisableSubmit(true)
    event.preventDefault();

    if (isEmpty(email)) {
      setEmailErr(true);
      setEmailErrText(getRequiredMessage("Email"));
    }
    if (isEmpty(password)) {
      setPasswordErr(true);
      setPasswordErrText(getRequiredMessage("Password"));
    }

    if (emailErr || passwordErr) {
      return;
    }

    if (isEmpty(email) || isEmpty(password)) {
      return;
    }
    const response = await login(axios, {email: email, password: password})
    if (response.result) {
      sendNotification('success', response.message)
      setAuthentication(response.token)
      navigate(from, {replace: true})
    } else {
      sendNotification('error', response.message)
    }
    setDisableSubmit(false)
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => handleSetEmail(e.target.value)}
              error={emailErr}
              helperText={emailErrText}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => handleSetPassword(e.target.value)}
              error={passwordErr}
              helperText={passwordErrText}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disableSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="#" style={{fontSize: 14, textDecoration: "none"}}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup" style={{fontSize: 14, textDecoration: "none"}}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
