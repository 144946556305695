import React, { useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface DragDropImageUploadProps {
  onFileDrop: (file: File) => void;
}

const DragDropImageUpload: React.FC<DragDropImageUploadProps> = ({ onFileDrop }) => {
  const [dragOver, setDragOver] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      setSelectedFileName(file.name);
      onFileDrop(file);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFileName(file.name);
      onFileDrop(file);
    }
  };

  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{
        border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
        padding: 2,
        textAlign: 'center',
        cursor: 'pointer',
        background: dragOver ? '#eee' : '#fafafa',
        width: '100%',
        mt: 2
      }}
    >
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="contained-button-file">
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <CloudUploadIcon style={{ fontSize: 60 }} />
          </IconButton>
          <Typography variant="subtitle1" component="span" gutterBottom>
            {selectedFileName ? `Selected Image: ${selectedFileName}` : 'Drag and drop here or click to select a product image'}
          </Typography>
          {selectedFileName && <Typography variant="body2" component="span">(Click to change)</Typography>}
        </Box>
      </label>
    </Paper>
  );
}

export default DragDropImageUpload;