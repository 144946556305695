import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {getStickerStatisticsDetails} from "../../api/admin";
import {DetailsStatistic} from "../../api/responses";
import {StickerDetailsReportFilter} from "../../api/requests";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import NoDataFound from "../../components/NoDataFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#a7a7a761',
        color: 'black',
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface AdminDashboardProps {
    filter: StickerDetailsReportFilter
    refetchData: boolean
}

const AdminDashboardDetails = ({filter, refetchData}: AdminDashboardProps) => {
    const axios = useAxiosPrivate();
    const [loading, setLoading] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)
    const [detailsStatistics, setDetailStatistics] = useState<DetailsStatistic[]>([])

    useEffect(() => {
        setLoading(true);
        getStickerStatisticsDetails(axios, filter)
            .then(response => {
                if (response.result) {
                    setSuccess(true);
                    if (response.data) {
                        setDetailStatistics(response.data)
                    } else {
                        setDetailStatistics([])
                    }
                } else {
                    setSuccess(false);
                }
            })
        setLoading(false);
    }, [axios, refetchData])

    const getTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">SL No</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Brand Id</StyledTableCell>
                            <StyledTableCell align="left">VisibleID From</StyledTableCell>
                            <StyledTableCell align="left">VisibleID To</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detailsStatistics.map((value, index) => (
                            <StyledTableRow key={`value.status-${index}`}>
                                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                <StyledTableCell align="left">{value.status}</StyledTableCell>
                                <StyledTableCell align="left">{value.brand_id}</StyledTableCell>
                                <StyledTableCell align="left">{value.visible_id_from}</StyledTableCell>
                                <StyledTableCell align="left">{value.visible_id_to}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <>
            {success && detailsStatistics.length > 0 && getTable()}
            {success && detailsStatistics.length == 0 && <NoDataFound />}
        </>
    );
}

export default AdminDashboardDetails;