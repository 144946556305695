import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useParams} from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNotificationStore} from "../../stores/useNotificationStore";
import {isEmpty} from "../../utils/stringUtils";
import {ThemeProvider} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Copyright from "../Copyright";
import {getBrandById, updateBrandInfo} from "../../api/admin";
import {Brand} from "../../model";
import {getRequiredMessage} from "../../constants/errormessages";

const brandInitialState: Brand = {
  id: '',
  name: '',
  created_at: '',
  updated_at: ''
}

const EditBrand = () => {
  const axios = useAxiosPrivate()
  const params = useParams()
  const theme = useTheme()

  const [brandName, setBrandName] = useState<string>('')
  const [brandNameErr, setBrandNameErr] = useState<boolean>(false)
  const [brandNameErrText, setBrandNameErrText] = useState<string>("")

  const [brand, setBrand] = useState<Brand>(brandInitialState)
  const [loading, setLoading] = useState<boolean>(false)

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const sendNotification = useNotificationStore((state) => state.sendNotification);

  const fetchData = async () => {
    const brandId = String(params.id)
    if (isEmpty(brandId)) {
      return Promise.reject('Brand id can not be blank!');
    }

    setLoading(true)
    return getBrandById(axios, brandId).then(response => {
      if (response.result) {
        setBrand(response.data)
        setBrandName(response.data.name)
        setLoading(false)
      } else {
        return Promise.reject(response.message)
      }
    })
  }

  useEffect(()=> {
    fetchData().catch(err => {
      sendNotification('error', err)
    })
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisableSubmit(true)
    updateBrandInfo(axios, {id: brand.id, name: brandName})
      .then(response => {
        if (response.result) {
          sendNotification('success', response.message)
        } else {
          sendNotification('error', response.message)
        }
      })
    setDisableSubmit(false)
  };

  const handleSetBrandName = (val : string) => {
    setBrandName(val)
    if (isEmpty(val)) {
      setBrandNameErr(true)
      setBrandNameErrText(getRequiredMessage("Brand Name"))
      return
    }
    if (val.length < 4) {
      setBrandNameErr(true)
      setBrandNameErrText("Brand Name must be greater than 3 character!")
      return
    }

    setBrandNameErr(false)
    setBrandNameErrText("")
  }

  useEffect(() => {
    if (brandNameErr) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [brandNameErr])

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Typography >
          Loading
        </Typography>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <DriveFileRenameOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Edit Brand
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={brand.id}
                  label="Brand Id"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={brandName}
                  label="Brand Name"
                  onChange={(e) => handleSetBrandName(e.target.value)}
                  error={brandNameErr}
                  helperText={brandNameErrText}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disableSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

export default EditBrand;