import create from 'zustand'

interface NotificationStore {
    type: 'error' | 'info' | 'success' | 'warning'
    content: string
    sendNotification: (type: 'error' | 'info' | 'success' | 'warning' , content: string) => void
    resetNotification: () => void
}

export const useNotificationStore = create<NotificationStore>()(
  (set) => ({
    type: 'info',
    content: '',
    sendNotification: (type, content) => {
        set(state=> ({
            type: type,
            content: content
        }))
    },
    resetNotification: () => {
        set(state=> ({
            type: 'info',
            content: ''
        }))
    }
  })
)

