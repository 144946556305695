import {useLocation} from "react-router-dom";
import {Breadcrumbs, Chip, emphasize} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const MUIBreadcrumb = () => {
  const {pathname} = useLocation();
  const pathnames = pathname.split("/").filter(item => item !== '')

  return (

    <Breadcrumbs aria-label="breadcrumb">
    {
      pathnames.filter((item, index) => index !== 0)
        .map((item, index) => {
        const title = item.split('-').map(word => word[0].toUpperCase() + word.substring(1)).join(' ')
        let link = `/${pathnames.slice(0, index + 1).join("/")}`
        if (index === pathnames.length - 1) {
          link = '#'
        }

        return (
          <StyledBreadcrumb key={title} component="a" href={link} label={title}/>
        )
      })
    }
    </Breadcrumbs>
  )
}

export default MUIBreadcrumb;