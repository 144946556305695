import {Box, colors} from "@mui/material";
import React from "react";
import {RoleConstants} from "../../../constants/constants";

export interface RolePillProps {
  role: string
}

const RolePill = ({role}: RolePillProps) => {
  let bgColor: string = colors['green'].A100
  if (role === RoleConstants.ROLE_ADMIN) {
    bgColor = colors['orange'].A400
  } else if (role === RoleConstants.ROLE_SUPER_ADMIN) {
    bgColor = colors['red'].A400
  } else if (role === RoleConstants.ROLE_BRAND) {
    bgColor = colors['blue'].A100
  }
  return (<Box mt={1} p={1} sx={{
      backgroundColor: bgColor,
      borderRadius: 10,
      textAlign: 'center',
    }}>{role}</Box>
  )
}

export default RolePill;